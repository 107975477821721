import {
  ProjectPropertiesToUpdateEnum,
  ScenePropertiesToUpdateEnum,
} from '../../../../../models/project-model';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../../../services/guards/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, take, takeUntil, throttleTime } from 'rxjs';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { SnackBarService } from 'src/app/services/utils/snack-bar.service';
import {
  CreativeStatusEnum,
  ProjectStatusEnum,
} from '../../../../../models/defines';

import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { IProject } from 'src/app/models/project-model';
import { DynamicItemType } from 'lottie-json-helper/lib/types';
import { MatDialog } from '@angular/material/dialog';
import { StartRecordDialogComponent } from 'src/app/components/dialogs/start-record-dialog/start-record-dialog.component';
import { ArtDirectorService } from 'src/app/services/art-director.service';
import { PaymentDialogComponent } from 'src/app/components/dialogs/plans-and-payments/payment-dialog/payment-dialog.component';
import { FeatureService } from 'src/app/services/feature-service.service';
import { PlanEnum, User } from 'src/app/models/user';
import { ProfileService } from 'src/app/services/show/profile.service';
import { ProjectRoutingService } from '../../../../../services/project/routes/project-routing.service';

@Component({
  selector: 'project-planning',
  templateUrl: './project-planning.component.html',
  styleUrls: ['./project-planning.component.scss'],
})
export class ProjectPlanningComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  teammates = [
    { name: 'Isaac G', profilePic: 'assets/images/demo/Kath.jpg' },
    { name: 'Isaac G', profilePic: 'assets/images/demo/lego.jpg' },
    { name: 'Isaac G', profilePic: 'assets/images/demo/shira.jpeg' },
  ];
  loadingData = false;
  project: IProject;

  projectTitle: FormControl<string>;
  projectBriefFormControl: FormControl<string>;

  generatingSnackbar: any;
  baseCdnUrl: string;
  projectCreativeDone: boolean;
  user: User;
  dialogRef: MatDialog;

  private onDestroy$: Subject<boolean> = new Subject();
  isPaymentDialogOpen = false;

  /// To know wether we are initializing the project or not
  /// So the project source will be the updated one
  /// useful when we move from project to project in the library
  isInitalizingProject = false;

  constructor(
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    public authentication: AuthenticationService,
    private config: ConfigurationService,
    private artDirector: ArtDirectorService,
    public projectAuthApiService: ProjectAuthApiService,
    private projectStoreService: ProjectStoreService,
    private dialog: MatDialog,
    private featureService: FeatureService,
    private profileService: ProfileService,
    private projectRoutingService: ProjectRoutingService
  ) {
    this.baseCdnUrl = this.config.baseCdnUrl;
  }

  ngOnInit(): void {
    this.user = this.profileService.user$.value;
    this.projectStoreService.projectChanged$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (project) => {
          if (!project || !this.isInitalizingProject) {
            return;
          }

          this.settingProjectFormFields(project);
        },
      });

    this.projectStoreService.projectWasNotChanged$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: () => {
          if (!this.isInitalizingProject) {
            return;
          }

          const currentProject = this.projectStoreService.getProject;
          if (!currentProject) {
            return;
          }

          this.initializeProject(currentProject);
          this.settingProjectFormFields(currentProject);
        },
      });
    this.projectStoreService.projectSource$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (project) => {
          if (!project || !this.isInitalizingProject) {
            return;
          }
          this.initializeProject(project);
        },
        error: (error) => {},
      });
  }

  private settingProjectFormFields(project: IProject) {
    this.projectTitle = new FormControl(project.title);
    this.projectBriefFormControl = new FormControl(
      project.creative?.prompt ?? ''
    );
  }

  private initializeProject(project: IProject) {
    this.project = project;
    this.projectCreativeDone = false;

    switch (project.creative?.status) {
      case CreativeStatusEnum.DONE:
        this.projectCreativeDone = true;
        this.closeGeneratingSnackbar();
        break;
      case CreativeStatusEnum.STRUCTURE:
        this.updateProjectInStructureStatus();
        break;

      case CreativeStatusEnum.GENERATING_IMAGES:
        this.updateProjectInGeneratingImagesStatus();
        break;
    }
  }

  ngAfterViewInit(): void {
    const projectId = this.route.snapshot.params['id'];

    if (projectId) {
      this.initProjectAsync(projectId);
    }
  }

  private async initProjectAsync(projectId: string) {
    try {
      this.loadingData = true;
      let project: IProject;
      try {
        this.isInitalizingProject = true;

        project =
          await this.projectStoreService.setProjectSourceIfNotExistedAsync(
            projectId,
            false,
            null
          );
      } catch (error: any) {
        console.error(
          `An error occurred while trying to get project. error: ${error.message}`
        );
        this.snackBarService.openMessage(
          'WOOPS, An Error Occurred. Please contact ASAP',
          null,
          10000,
          {
            panelClass: 'snackbar',
          }
        );
        return;
      }
      if (project?.creative?.status === CreativeStatusEnum.DONE) {
        this.openPaymentDialog();
      }

      await this.artDirector.preloadProjectAssetsAsync(project);

      console.log('production is:', this.project);
    } catch (e) {
      console.error(
        'Error occurred while trying to get production with ID:',
        e
      );
    } finally {
      this.loadingData = false;
    }
  }

  closeGeneratingSnackbar() {
    if (this.generatingSnackbar) {
      this.snackBarService.openMessage(
        'Your project is ready 🥳 ',
        null,
        4000,
        {
          panelClass: 'snackbar',
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.generatingSnackbar) {
      this.generatingSnackbar.dismiss();
    }
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public startProduction() {
    if (!this.projectCreativeDone) {
      this.dialog.open(StartRecordDialogComponent, {
        width: '450px',
        height: '220px',
      });
    } else {
      this.projectRoutingService.goToAsync(
        ProjectStatusEnum.RECORDING,
        this.project.id
      );
    }
  }

  public updateProjectName(event: string) {
    const projectId = this.project.id;
    const params = {
      projectId: projectId,
      value: event,
      key: ProjectPropertiesToUpdateEnum.PROJECT_TITLE,
    };
    this.projectAuthApiService
      .updateProjectProperty$(params)
      .pipe(throttleTime(500))
      .subscribe((updatedProject) => {
        if (!updatedProject) {
          return;
        }
        const propertiesToUpdate = {
          [params.key]: params.value,
        };
        this.projectStoreService.updateProjectProperties(
          projectId,
          propertiesToUpdate
        );
      });
  }

  public updateSceneTitle(event: any) {
    const params = {
      projectId: this.project.id,
      sceneId: event.sceneId,
      value: event.name,
      key: ScenePropertiesToUpdateEnum.SCENE_TITLE,
    };
    this.projectAuthApiService
      .updateSceneProperty$(params)
      .pipe(throttleTime(500))
      .subscribe((updatedScene) => {
        if (!updatedScene) {
          return;
        }
        this.projectStoreService.updateSceneProperty(
          params.projectId,
          params.sceneId,
          params.key,
          params.value
        );
      });
  }

  public openPaymentDialog() {
    if (this.isPaymentDialogOpen) {
      return;
    }

    this.featureService
      .hasFeatureAccess('AB_mustpay_free')
      .pipe(take(2))
      .subscribe({
        next: (hasAccess) => {
          const shouldShowPaymentsDialog =
            this.user.plan === PlanEnum.FREE && hasAccess === true;

          if (shouldShowPaymentsDialog && !this.isPaymentDialogOpen) {
            this.isPaymentDialogOpen = true;

            const dialogRef = this.dialog.open(PaymentDialogComponent, {
              disableClose: true,
              panelClass: 'payments-dialog',
              maxHeight: '90vh',
            });

            dialogRef.afterClosed().subscribe(() => {
              this.isPaymentDialogOpen = false; // Reset the flag when dialog closes
            });
          } else {
            this.isPaymentDialogOpen = false;
          }
        },
        error: (error) => {
          console.warn(`NO FEATURE FLAG DATA`);
          this.isPaymentDialogOpen = false;
        },
      });
  }

  private updateProjectInStructureStatus() {
    this.generatingSnackbar = this.snackBarService.openMessage(
      'Generating Project - Creating Script...🚀',
      null,
      0,
      {
        panelClass: 'snackbar',
      }
    );

    if (this.project.scenes?.length > 1) {
      console.warn(
        'Project is in a structure creative status, removing all scenes after the first'
      );
      this.project.scenes = [this.project.scenes[0]];
    }
  }

  private updateProjectInGeneratingImagesStatus() {
    this.generatingSnackbar = this.snackBarService.openMessage(
      'Generating Project - Creating Images…. 📷',
      null,
      0,
      {
        panelClass: 'snackbar',
      }
    );
  }

  private updateImages(project: IProject) {
    project.scenes.forEach((scene) => {
      let selectedTake = scene.takes.find(
        (take) => take.id === scene.selectedTakeId
      );
      const sceneToChangeImage = selectedTake.copy.dynamicLottieChanges.find(
        (dynamicLottieChange) =>
          dynamicLottieChange.type === DynamicItemType.IMAGE
      );
      if (sceneToChangeImage) {
        const sceneToChange = project.scenes.find(
          (scene) => scene.id === sceneToChangeImage.id
        );

        const compositionToChange = selectedTake.copy.dynamicLottieChanges.find(
          (dynamicLottieChanges) =>
            dynamicLottieChanges.type === sceneToChange.composition.type
        );
        if (sceneToChange && compositionToChange) {
          selectedTake = sceneToChange.takes[0];
        }
      }
    });
  }

  protected readonly ProjectStatus = ProjectStatusEnum;
}
