import { ConfigurationService } from '../../../../../services/configuration.service';
import { ExportStatus, ProjectStatusEnum } from '../../../../../models/defines';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/guards/authentication.service';

import { EditJobStatusEnum } from 'src/app/models/job/edit-job-schema';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { IProjectInDTO } from 'src/app/models/project-model';
import { ProfileService } from '../../../../../services/show/profile.service';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { ArtDirectorService } from '../../../../../services/art-director.service';
import { ProjectRoutingService } from 'src/app/services/project/routes/project-routing.service';

export enum GroupByEnum {
  NONE = 'None',
  SERIES = 'Series',
}

@Component({
  selector: 'my-library',
  templateUrl: './my-library.component.html',
  styleUrls: ['./my-library.component.scss'],
})
export class MyLibraryComponent implements OnInit {
  groupByEnum = GroupByEnum;
  loadingData: boolean = false;
  projects: IProjectInDTO[] = [];
  selectedGroupByOption = GroupByEnum.NONE;
  groupOptions = [GroupByEnum.NONE, GroupByEnum.SERIES];
  userImg = this.authentication.getUser().profileImgUrl;
  baseCdn: string;
  personalizedDynamics: IDynamicLottieChange[];
  lottieOptions = {
    path: 'assets/animations/production/rocket.json',
    autoplay: true,
    loop: true,
  };
  dataInitialized = false;
  tabs = ['All'];

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public authentication: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private config: ConfigurationService,
    public projectAuthApiService: ProjectAuthApiService,
    public profileService: ProfileService,
    private artDirector: ArtDirectorService,
    private projectRoutingService: ProjectRoutingService
  ) {
    this.baseCdn = this.config.baseCdnUrl;
  }

  ngOnInit(): void {
    this.personalizedDynamics =
      this.artDirector.extractDynamicLottieByUserBranding();
    this.initializeTabs();
    // this.demoService.getAllRecordings();
    this.getData();
    this.cdRef.detectChanges();
  }

  getProjectsByStatus(status: string): any[] {
    if (status === 'All') {
      return this.projects;
    }
    return this.projects.filter(
      (project) => project.statuses?.general === status
    );
  }

  initializeTabs() {
    this.tabs = [
      'All',
      ProjectStatusEnum.PLANNING,
      ProjectStatusEnum.RECORDING,
      ProjectStatusEnum.EDITING,
      ProjectStatusEnum.DONE,
    ];
  }

  createNewProduction(seriesId?: string) {
    this.router.navigate([{ outlets: { panel: ['new'] } }], {
      relativeTo: this.activeRoute.parent,
    });
  }

  public onGroupOptionChange() {
    // if (this.selectedGroupByOption === GroupByEnum.SERIES) {
    //   this.seriesList;
    // }
  }

  public getStreamExportStatusAndCount(stream: IProjectInDTO): {
    exportStatus: ExportStatus;
    ongoingExportsCount: number;
  } {
    const completedExportsCount =
      stream?.edits?.filter(
        (job) => job?.status === EditJobStatusEnum.COMPLETED
      ).length ?? 0;
    const ongoingExportsCount =
      stream?.edits?.filter(
        (job) =>
          job?.status !== EditJobStatusEnum.DUMMY &&
          job?.status !== EditJobStatusEnum.STARTED
      ).length ?? 0;
    let exportStatus: ExportStatus;
    if (completedExportsCount >= 1) {
      exportStatus = ExportStatus.READY;
    } else {
      exportStatus = ExportStatus.NOT_READY;
    }
    // if(completedExportsCount===0 && ongoingExportsCount===0){
    // exportStatus =PostProdExportStatus.NOT_READY;
    // }
    return { exportStatus, ongoingExportsCount };
  }

  getTimeDiff(date: Date) {
    if (!date) {
      return 0;
    }
    const diff = new Date().getTime() - new Date(date).getTime();
    // const diff = now.getTime() - createdAt.getTime();
    return diff / (1000 * 60 * 60);
  }

  goToProject(project: IProjectInDTO) {
    if (
      project?.id &&
      project.statuses?.general !== ProjectStatusEnum.EXPORTING
    ) {
      this.projectRoutingService.goToAsync(
        project.statuses?.general,
        project.id
      );
    }
  }

  private async getData() {
    try {
      this.loadingData = true;
      // this.productions = await this.productionsApi.getAllProductions();

      this.projectAuthApiService.getAllProjects$().subscribe({
        next: (projects) => {
          if (!projects) return;
          this.projects = projects;
          this.dataInitialized = true;
          this.loadingData = false;
          // console.log('projects', this.projects);
        },
        error: (error) => {
          `Could not get history recordings, error: ${error}`;
        },
      });
    } catch (ex) {
      console.error('Error ocoured while trying to get episodes');
      this.loadingData = false;
    } finally {
    }
  }
}
