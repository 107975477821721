import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'minuteSeconds'
})
/**
 * `MinuteSecondsPipe` Class
 *
 * This class implements a custom pipe in Angular, designed to transform a numerical
 * value representing a time duration in seconds into a formatted string showing
 * minutes and seconds in `MM:SS` format.
 *
 * Example Usage:
 * - `transform(150)` will output `02:30`.
 * - `transform(-75)` will output `-01:15`.
 */
export class MinuteSecondsPipe implements PipeTransform {

  /**
   * Transforms the given number of seconds into a formatted string.
   *
   * @param value The number of seconds to be transformed. This can be any integer,
   *              positive or negative.
   * @returns A string representing the formatted duration in `MM:SS` format.
   *          For negative input, the output is prefixed with a `-` sign.
   *
   * @example
   * transform(330) // returns '05:30'
   * transform(-45) // returns '-00:45'
   */
  transform(value: number): string {
    if (value >= 0) {
      const minutes: number = Math.floor(value / 60);
      return minutes.toString().padStart(2, '0') + ':' +
        (value - minutes * 60).toString().padStart(2, '0');
    } else {
      value = -value;
      const minutes: number = Math.floor(value / 60);

      return '-' + minutes.toString().padStart(2, '0') + ':' +
        (value - minutes * 60).toString().padStart(2, '0');
    }
  }
}
