import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PrompterSyncronizerService } from '../../../../services/prompter-syncronizer.service';

@Component({
  selector: 'app-dummy-text',
  templateUrl: './dummy-text.component.html',
  styleUrls: ['./dummy-text.component.scss'],
})
export class DummyTextComponent implements OnDestroy {
  // @Input({ alias: 'hosted', required: false }) hosted: boolean;
  @Input({ alias: 'dummyReadMode', required: true }) dummyReadMode: boolean;
  manualText: string;

  private onDestroy$ = new Subject<void>();

  constructor(
    public prompterSync: PrompterSyncronizerService,
  ) {
  }

  private subscribeToPromptSync() {
    this.prompterSync.currentPrompterText$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((newText) => {
        if (!newText) {
          return;
        }
        if (this.manualText === newText) {
          return;
        }
        this.manualText = newText ?? '';
      });
  }

  ngOnInit() {
    this.subscribeToPromptSync();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
