import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DynamicItemType,
  IDynamicLottieChange,
} from 'lottie-json-helper/lib/types';
import { BehaviorSubject } from 'rxjs';
import { ColorsHelper } from 'lottie-json-helper/lib/helpers/colors-helper';
import { IDesign } from '../../../models/design.model';
import { IScene } from 'src/app/models/project/scene-model';
import { ITake } from 'src/app/models/project/take/take-model';
import { IDynamicLottieData } from '../../../models/lottie/lottie-defines';
import { FunctionsHelperService } from '../../../services/functions-helper.service';
import { IProject } from 'src/app/models/project-model';

@Component({
  selector: 'app-scene-setup-dialog',
  templateUrl: './scene-setup-dialog.component.html',
  styleUrls: ['./scene-setup-dialog.component.scss'],
})
export class SceneSetupDialogComponent {
  lottieConfigOnDisplay: IDynamicLottieData;

  scene: IScene;
  takeToDisplay: ITake; // The take that we chose to display
  take: ITake; // The take we are working on
  originalTake: ITake; // The original take we got
  design: IDesign;

  dynamicTexts: IDynamicLottieChange[] = [];
  dynamicColors: IDynamicLottieChange[] = [];
  dynamicGradients: IDynamicLottieChange[] = [];
  dynamicImages: IDynamicLottieChange[] = [];
  dynamicVideos: IDynamicLottieChange[] = [];

  imageDynamicTypes = [DynamicItemType.LOGO, DynamicItemType.IMAGE];
  colorDynamicTypes = [
    DynamicItemType.COLOR_RGB,
    DynamicItemType.STROKE,
    DynamicItemType.FILL,
  ];
  videoDynamicTypes = [DynamicItemType.VIDEO];
  gradientDynamicTypes = [DynamicItemType.GRADIENT];
  ignoredDynamicTypes = [DynamicItemType.MAX_CHR];

  TEMP_PROPERTY_NAME = 'temp_dynamic';
  HIDDEN_PROPERTY_NAME = 'hidden_dynamic';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      scene: IScene;
      take: ITake;
      design: IDesign;
      project: IProject;
    },
    public dialogRef: MatDialogRef<SceneSetupDialogComponent>
  ) {
    // console.log('the scene', dialogData.scene);
    this.scene = dialogData.scene;
    this.design = dialogData.design;

    /// TODO: Take care of no takes at all
    this.originalTake = dialogData.take ?? this.scene.chosenTake;

    // We don't want to change the values on the original objects, so till we save them, we will only apply them on a copy
    this.take = this.originalTake.clone(
      this.dialogData.project.id,
      this.dialogData.scene.id
    );
    const duplicatedChanges: IDynamicLottieChange[] =
      this.take?.copy?.dynamicLottieChanges;

    // Split the dynamics to multiple arrays represrenting each tab.
    duplicatedChanges?.forEach((dynamicChange) => {
      // Images
      if (this.imageDynamicTypes.includes(dynamicChange.type)) {
        this.dynamicImages.push(dynamicChange);
      }
      //  Colors
      else if (this.colorDynamicTypes.includes(dynamicChange.type)) {
        // Change color to hex
        dynamicChange[this.TEMP_PROPERTY_NAME] = ColorsHelper.lottieRGBAtoRGBA(
          dynamicChange.value as number[]
        );
        // To group similar colors (same position, we will apply hidden to everything that comes after the first color of the same group
        if (
          this.dynamicColors.some((_dynamic) => {
            return _dynamic.position === dynamicChange.position;
          })
        ) {
          dynamicChange[this.HIDDEN_PROPERTY_NAME] = true;
        }
        this.dynamicColors.push(dynamicChange);
      }
      //  Videos
      else if (this.videoDynamicTypes.includes(dynamicChange.type)) {
        this.dynamicVideos.push(dynamicChange);
      } else if (dynamicChange.type === DynamicItemType.STAGE_POSITION) {
        /// Nothing for now
      }
      //     All the rest - as texts
      else {
        /// Check that is not in the ignored
        if (!this.ignoredDynamicTypes.includes(dynamicChange.type)) {
          this.dynamicTexts.push(dynamicChange);
        }
      }
    });

    // We start with the original take, till we apply the changes
    this.lottieConfigOnDisplay = {
      dynamicLottieChanges: this.originalTake.copy.dynamicLottieChanges,
      layout: this.scene?.composition?.layouts[0],
      basePath: this.design?.basePath,
    };
  }

  selectorDialogOpenEvent(event) {
    // console.log(event);

    /// When another dialog is open, hide this one underneath
    if (event) {
      this.dialogRef.addPanelClass('force-hidden');
    } else {
      this.dialogRef.removePanelClass('force-hidden');
    }
  }

  // private setLottieOptions(newLottiePath: string) {
  //     const options: any = {
  //         path: newLottiePath,
  //         assetsPath: `${this.config.baseCdnUrl}`,
  //     };
  //     this.mainLottieOptions$.next(options);
  // }

  /// To validate that only 1 video is being auto played .
  // toggle(event: MatSlideToggleChange, video: IVideoAssetAndFlag) {
  //     if (event.checked) {
  //         const otherVideos = this.dynamicVideos.filter(
  //             (tempVideo) => tempVideo.videoAsset._id !== video.videoAsset._id
  //         );
  //         otherVideos.forEach((otherVideo) => (otherVideo.autoPlay = false));
  //         // this.cdr.detectChanges();
  //     }
  // }

  /**
   * Use this function to remove duplicates in cases like twice sfl_color_fill
   * (those cases can happen if we want to make few elements by the same color and make the color dynamic)
   * @param objects all the dynamic objects we have from lottie json (by sfl class)
   * @returns an array of the dynamic elemnts without duplicates
   */
  removeDuplicates(objects: any): any[] {
    const uniqueObjectsMap = new Map<number, any>();

    for (const obj of objects) {
      uniqueObjectsMap.set(obj.id, obj);
    }
    return Array.from(uniqueObjectsMap.values());
  }

  // applyColorToBothElements(newColor: string, aiIndex: number) {
  //     const rgbaNewColor = this.dynamicAssetsHelper.hexToRgbaString(newColor, 1);
  //     this.tempColors.forEach((oldColor) => {
  //         if (oldColor.ai_index === aiIndex) {
  //             oldColor.newText = rgbaNewColor;
  //         }
  //     });
  //
  //     console.log(this.tempColors, aiIndex, rgbaNewColor);
  // }

  /***
   * This is the apply button on the dynamic texts popup
   */
  preview() {
    this.lottieConfigOnDisplay = {
      ...this.lottieConfigOnDisplay,
      dynamicLottieChanges: this.take.copy.dynamicLottieChanges,
    };
  }

  save() {
    this.preview();

    if (this.take?.copy?.dynamicLottieChanges) {
      // Remove temp properties
      const copy = JSON.parse(
        JSON.stringify(this.take.copy.dynamicLottieChanges)
      );

      // Remove the 'temp' property from each item in the array
      // Also remove the hidden property name if it exists
      copy.forEach((item: IDynamicLottieChange) => {
        delete item[this.TEMP_PROPERTY_NAME];
        delete item[this.HIDDEN_PROPERTY_NAME];
      });

      // Copy the changes to the original take
      this.originalTake.copy.dynamicLottieChanges = copy;
    } else {
      console.error('Something went wrong, the dynamic changes are null');
    }
    this.dialogRef.close({
      success: true,
      takeChanges: this.take.copy,
    });
  }

  /**
   * Gets an rgba and a color position
   * @param rgbaColor the new color to apply
   * @param position the color group to change
   */
  applyColorToElements(rgbaColor: string, position: number) {
    if (position === null || position === undefined) {
      console.warn('No color group position was present');
      return;
    }
    // Turn to lottie color
    const lottieNewColor = ColorsHelper.rgbaToLottieRGBA(rgbaColor);

    /// Apply the color to all the colors in the same position (Meaning stroke, fill, etc...)
    this.dynamicColors.forEach((dynamicChange) => {
      if (dynamicChange.position === position) {
        dynamicChange.value = lottieNewColor;
      }
    });
    // console.log(this.tempColors, aiIndex, rgbaNewColor);
  }

  imageSelected(image: string, dynamicText: IDynamicLottieChange) {
    // console.log(image);
    // console.log(dynamicText);
    if (image && dynamicText) {
      // Make the path relative
      dynamicText.value = image;
    }
  }

  // insertNewVideo(video: IAsset) {
  //     if (!video) return;
  //     console.log('trying to insert new video');
  //     if (
  //         !this.tempVideos.some(
  //             (existedVideo) => existedVideo.videoAsset._id === video._id
  //         )
  //     ) {
  //         console.log('inserting new video', video);
  //         const videoANdFlag: IVideoAssetAndFlag =
  //             this.creatingVideoAssetAndFlag(video);
  //         this.tempVideos.push(videoANdFlag);
  //     }
  // }

  // switchExistedVideo(newVideo: IAsset, oldVideo: IAsset) {
  //     if (
  //         this.tempVideos.some(
  //             (existedVideo) => existedVideo.videoAsset._id === newVideo._id
  //         )
  //     ) {
  //         console.log('video already existed!', newVideo);
  //         this.snackBar.open('Video already existed in the scene', 'Dismiss', {
  //             duration: 2000,
  //         });
  //         return;
  //     }
  //
  //     const existedVideoIndex = this.tempVideos.findIndex(
  //         (existedVideo) => existedVideo.videoAsset._id === oldVideo._id
  //     );
  //
  //     if (existedVideoIndex >= 0) {
  //         const videoAndFlag = this.creatingVideoAssetAndFlag(newVideo);
  //         this.tempVideos[existedVideoIndex] = videoAndFlag;
  //     }
  // }

  // private creatingVideoAssetAndFlag(video: IAsset): IVideoAssetAndFlag {
  //     /// auto playing if non of the existed videos is auto playing
  //     const autoPlay = !this.tempVideos.some((tempVideo) => tempVideo.autoPlay);
  //     return {
  //         videoAsset: video,
  //         autoPlay: autoPlay,
  //     };
  // }
}
