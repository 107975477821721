import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DirectorCommand, StreamStatus } from '../models/defines';
import { ITextPortion, TextTypeEnum } from '../models/Teleprompter';
import { CopywriterService } from './show/copywriter.service';
import { codeSync } from 'browser-sync/dist/default-config';

declare var webkitSpeechRecognition: any;
declare var webkitSpeechGrammarList: any;

interface SplitText {
  ai_text: Map<string, ITextPortion>;
}

@Injectable({
  providedIn: 'root',
})
export class VoiceRecognitionService {
  words: string[] = [];
  text: string = '';
  isNewText: boolean = true;
  slidingWindow = 5;

  stopWords = ['hmm', 'it', 'i', 'you', 'this', 'the', 'is', 'a', 'are', 'as', 'but'];

  recognition = null;
  speechRecognitionOn = false;

  public speechText = '';
  currentSpeechIndex;

  public splitText = new Map<string, ITextPortion>();
  public splitTextOnlyWords: ITextPortion[] = [];
  public currentIndex = 0;
  lastFoundWord = new BehaviorSubject<ITextPortion>(null);
  notifyScrollTeleprompter = new BehaviorSubject<ITextPortion>(null);
  notifySplitTextProcessed = new BehaviorSubject<SplitText>(null);

  splitterRegexp = new RegExp(`(?<${TextTypeEnum.Word}>\\w+)|(?<${TextTypeEnum.Space}>\\s+)|(?<${TextTypeEnum.Symbol}>[^\\s\\w]+)`, 'g');

  speechTempWords = '';
  jumpedToWord: boolean = false;

// text.match(/\w+|\s+|[^\s\w]+/g)
  constructor(private copywriter: CopywriterService) {
    if (typeof webkitSpeechRecognition !== 'undefined') {
      this.recognition = new webkitSpeechRecognition();
    } else {
      console.warn('webkitSpeechRecognition is not supported in this environment.');
      // Handle the case where speech recognition is not available
    }

    if (typeof webkitSpeechGrammarList !== 'undefined') {
      console.log('webkitSpeechGrammarList exists');
    } else {
      console.warn('webkitSpeechGrammarList is not supported in this environment.');
      // Handle the case where speech recognition is not available
    }

    this.init();

    this.copywriter.copy.subscribe((newText) => {
      this.isNewText = true;
      this.text = newText;
      this.splitTextOnlyWords = [];
      this.lastFoundWord = new BehaviorSubject<ITextPortion>(null);
      if (newText) {
        this.splitText = new Map<string, {
          text: string;
          type: TextTypeEnum;
          done: boolean;
          uuid: string,
          index: number
        }>();

        const words = this.text.toLowerCase().match(/\b\w+\b/g); // Convert to lowercase and extract words

        // Use a Set to keep only unique words.
        const uniqueWords = [...new Set(words)];

        // Create the grammar
        const grammar = `#JSGF V1.0; grammar words; public <word> = ${uniqueWords.join(' | ')} ;`;
        const speechRecognitionList = new webkitSpeechGrammarList();
        speechRecognitionList.addFromString(grammar, 1);
        this.recognition.grammars = speechRecognitionList;
        let lastMatch;
        let index = 0;

        while (lastMatch = this.splitterRegexp.exec(newText)) {
          index++;


          /// Split the text to 3 Groups: WORD SPACE AND SYMBOL
          if (lastMatch) {
            let word = lastMatch.groups[TextTypeEnum?.Word];
            let space = lastMatch.groups[TextTypeEnum?.Space];
            let symbol = lastMatch.groups[TextTypeEnum?.Symbol];

            let uuid = uuidv4();
            if (word) {
              this.splitText.set(uuid, { text: word, type: TextTypeEnum.Word, done: false, uuid: uuid, index: index });
              // If it's a stop word, don't add it

              const lowerCased = word.toLowerCase();
              if (!this.stopWords.some(word => {
                return word === lowerCased;
              })) {
                /// Push the lower cased word
                this.splitTextOnlyWords.push({
                  text: lowerCased,
                  type: TextTypeEnum.Word,
                  done: false,
                  uuid: uuid,
                  index: index,
                });
                // console.log('tamtam', this.splitTextOnlyWords);
              }


            } else if (space) {
              this.splitText.set(uuid, {
                text: space,
                type: TextTypeEnum.Space,
                done: false,
                uuid: uuid,
                index: index,
              });
            } else if (symbol) {
              this.splitText.set(uuid, {
                text: symbol,
                type: TextTypeEnum.Symbol,
                done: false,
                uuid: uuid,
                index: index,
              });
            }
          }
          // Avoid infinite loop
          if (!this.splitterRegexp.global) {
            break;
          }
        }

        this.notifySplitTextProcessed.next({ ai_text: this.splitText });
        // let result = splitterRegexp.exec(newText);
        // console.log('All text', this.splitText)
        // console.log('Words', this.splitTextOnlyWords)
      }

    });

  }


  init() {
    if (!this.recognition) {
      return;
    }

    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';
    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      //   if (!(this.speechTempWords?.length > 0)) {
      //     return;
      //   }
      if (transcript) {
        const regex = new RegExp(`\\b(${this.speechTempWords})\\b`);
        // console.log('regex', regex);
        let newAddition = transcript;

        /// Remove the previous words from the result to get only what's new
        /// Avoid replacing very short words
        // TODO - Fix new addition -
        if (this.speechTempWords?.length > 1) {
          newAddition = transcript.toLowerCase().replace(regex, '');
        }

        /// Split to words
        let srTextWords = newAddition.match(/\w+/g);
        if (srTextWords) {
          srTextWords = this.checkSentence(srTextWords);
          srTextWords.forEach((word) => this.newWord(word));
        }
      }
      this.speechTempWords = transcript.toLowerCase();
    });
  }

  checkSentence(sentence) {
    const maxWindowSize = 10;
    const startTextIndex = Math.max(0, this.currentIndex - maxWindowSize);
    const recentTextWords = this.splitTextOnlyWords.slice(startTextIndex, this.currentIndex);

    for (let i = 0; i < sentence.length; i++) {
      const threeWords = sentence.slice(i, i + 2);
      const matches = threeWords.every(word =>
        recentTextWords.some(
          textWord => {
            return textWord.text === word && textWord.done === true;
          },
        ));
      if (matches) {
        // Delete all words from the start up to and including the matched sequence
        sentence = sentence.splice(0, i + 3); // This removes all words before and including the three words
        break; // Exit the loop after removal since the array has changed
      }
    }
    // Check in the last 10 words (Sliding window)
    // Check from the beginning of the sentence for at least 3 words sequence

    // Remove all the words we found matching (3 or more words)

    // Return the new sentence (might be empty)
    return sentence;
  }

  newWord(word) {
    if (this.isNewText) {
      this.isNewText = false;
      if (!this.jumpedToWord) {
        this.currentIndex = 0;
      }
    }
    this.jumpedToWord = false;
    const slidingWindowArr = this.splitTextOnlyWords.slice(this.currentIndex, Math.min(this.currentIndex + this.slidingWindow, this.splitTextOnlyWords.length));
    for (let i = 0; i < slidingWindowArr.length; i++) {
      const slidingWord = slidingWindowArr[i];
      let counter = 0;
      if (counter < (i - 2)) {
        break;
      }
      if (slidingWord.text === word && !slidingWord.done || (word == 'shuffle' && slidingWord.text == 'shuffll')) {
        counter++;
        let found = false;
        this.splitTextOnlyWords.find((el) => {
          if (word === el.text && !el.done && !found) {
            el.done = true;
            found = true;
          }
        });
        const newIndex = this.currentIndex + i + 1;
        // run over the previous elements again invoke their events
        for (let f = 0; f <= i; f++) {
          let originalFromSplitArray = this.splitText.get(slidingWindowArr[f].uuid);

          if (originalFromSplitArray.events?.length > 0) {
            this.invokeWordEvent(originalFromSplitArray);
          }
        }
        this.currentIndex = newIndex;
        this.lastFoundWord.next(slidingWord);
        this.notifyScrollTeleprompter.next(slidingWord);
        break;
      }
    }
  }

  jumpToWord(word) {
    this.jumpedToWord = true;
    this.currentIndex = this.splitTextOnlyWords.findIndex(splitWord => {
      return splitWord.uuid === word.uuid;
    });
    // for (let i = 0; i < this.splitTextOnlyWords.length; i++) {
    //   // Set done to true for words up to currentIndex, false for the rest
    //   this.splitTextOnlyWords[i].done = i < this.currentIndex + 1;
    // }
    this.lastFoundWord.next(this.splitTextOnlyWords[this.currentIndex]);
  }


  invokeWordEvent(betweenWord: ITextPortion) {
    // betweenWord.events.forEach((event) => {
    //     // console.log('invoking event', event)
    //     this.formatManagerService.notifyDirectorCommand.next(event as DirectorCommand);
    // })
  }

  start() {
    if (this.speechRecognitionOn) {
      return;
    }
    this.speechRecognitionOn = true;
    this.recognition.start();
    this.recognition.addEventListener('end', (condition) => {
      if (!this.speechRecognitionOn) {
        this.recognition.stop();
        this.currentIndex = 0;
        this.copywriter.setCopy(this.text);
        this.lastFoundWord = new BehaviorSubject<ITextPortion>(null);
        // this.splitTextOnlyWords = [];
        // this.splitText.forEach((text => {
        //   text.done = false;
        // }));
      } else {
        this.wordConcat();
        /// Restart the service
        try {
          this.recognition.start();
        } catch (e) {
          return;
        }
      }
    });
  }

  stop() {
    if (!this.speechRecognitionOn) {
      return;
    }
    this.speechRecognitionOn = false;
    this.wordConcat();
    this.recognition.stop();
    // console.log("End speech recognition")
  }

  wordConcat() {
    /// here we get the full text when someone stops talking.
    this.speechText = this.speechText + ' ' + this.speechTempWords;

    // next window
    // let slidingWindowArr = this.splitTextOnlyWords.splice(this.currentIndex, this.slidingWindow)
    //
    // for (let i = 0; i < srTextWords.length; i++) {
    // run over the window to look for the word
    // if()
    // }

    this.speechTempWords = '';
  }

}
