import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import {
  IBasicVideoLayer,
  IVideoLayer,
  IVideoLayerInDTO,
  IVideoLayerOutDTO,
} from './layers/video-model';
import { ILottieLayerInDTO, ILottieOutDTO } from './layers/lottie-model';
import { KeysMatching } from '../../defines';
import { IComposeAndPlayOrNot } from '../../job/editor-defines';
import { TakeRecorderManager } from '../controllers/take-recorder.manager';
import { VideoLayerIndexdbController } from 'src/app/models/project/controllers/video-layer-indexd.controller';
import { VideoLayerApiService } from 'src/app/services/api/auth/projects/video-layer-api.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import { ITranscript } from './transcript-model';
import { VideoEditTake } from '../../job/edit-job-schema';
import { ITrimLayers } from '../edit/edit-model';

export type TakeUpdateableProperties = Pick<
  ITakeOutDTO,
  'startTime' | 'endTime' | 'copy' | 'videoLayers' | 'duration' | 'status'
>;
export type UpdatableTakeProperty = KeysMatching<TakeUpdateableProperties, any>;

export interface IUpdateTakePropertie {
  key: UpdatableTakeProperty;
  value: any;
}

export interface ITakeUpdate<K extends keyof TakeUpdateableProperties> {
  key: K;
  value: ITakeOutDTO[K];
}

export type bla = Partial<ITakeOutDTO>;

function updateTakeProperties<K extends keyof ITakeOutDTO>(
  project: any,
  sceneId: string,
  takeId: string,
  key: K,
  value: ITakeOutDTO[K]
): any {
  return {
    ...project,
    scenes: project.scenes.map((scene) =>
      scene.id === sceneId
        ? {
            ...scene,
            takes: scene.takes.map((take) =>
              take.id === takeId
                ? {
                    ...take,
                    [key]: value,
                  }
                : take
            ),
          }
        : scene
    ),
  };
}

export enum TakeStatusEnum {
  NOT_RECORDED = 'not-recorded',
  RECORDING = 'recording',
  RECORDED = 'recorded',
}

export interface ITakeCopy {
  dynamicLottieChanges: IDynamicLottieChange[];
  transcript?: ITranscript;
}

export interface IBasicTake {
  id: string;
  startTime: number;
  endTime: number;
  duration: number;
  number: number;
  copy: ITakeCopy;
  videoLayers?: IBasicVideoLayer[];
  lottieLayers: ILottieOutDTO[];
  status: TakeStatusEnum;
  isPlaying?: boolean;
}

export interface ITakeOutDTO extends IBasicTake {
  lottieLayers: ILottieOutDTO[];
  videoLayers: IVideoLayerOutDTO[];
}

export interface ITakeInDTO extends IBasicTake {
  lottieLayers: ILottieLayerInDTO[];
  videoLayers?: IVideoLayerInDTO[];
}

export interface ITakeWithLottieComposed extends ITakeInDTO {
  title: string;
  lottieComposedConfigs: IComposeAndPlayOrNot;
}

export interface ITakeConfigs extends ITakeWithLottieComposed {
  videoLayers: IVideoLayer[]; /// Locally to upload in server
  recordUniqueId: string; /// unique record id combined with media streamer
}

/**
 * Client take that we transform from in to local, then from local to out
 */
export interface ITake extends ITakeConfigs {
  videoLayerDBController: VideoLayerIndexdbController;
  videoEditTake: VideoEditTake;

  addVideoLayerToTakeAsync(videoPositionId: string): Promise<IVideoLayerInDTO>;

  appendChunkToVideoLayerAsync(chunk: Blob): Promise<void>;

  setMediaRecorder(stream: MediaStream): MediaRecorder;

  get mediaRecorderOptions(): MediaRecorderOptions;

  get currentBaseMimetype(): string;

  toJSON(): any;

  clone(projectId: string, sceneId: string): ITake;
}

export class Take implements ITake {
  videoLayers: IVideoLayer[];
  recordUniqueId: string;
  lottieComposedConfigs: IComposeAndPlayOrNot;
  title: string;
  videoLayerDBController: VideoLayerIndexdbController;
  lottieLayers: ILottieLayerInDTO[];
  id: string;
  startTime: number;
  endTime: number;
  duration: number;
  number: number;
  copy: ITakeCopy;
  status: TakeStatusEnum;
  videoEditTake: VideoEditTake;
  toCleanAudio: boolean;
  toSegmentVideo: boolean;
  private recorderManager: TakeRecorderManager;

  constructor(
    takeConfigs: ITakeConfigs,
    projectId: string,
    private sceneId: string,
    private sceneName: string,
    videoLayerController: VideoLayerIndexdbController,
    private videoLayerApi: VideoLayerApiService,
    private profileService: ProfileService
  ) {
    Object.assign(this, takeConfigs);

    this.recorderManager = new TakeRecorderManager(
      projectId,
      sceneId,
      this.id,
      this.videoLayerApi,
      this.profileService,
      null
    );
    this.videoLayerDBController = videoLayerController;
    this.videoEditTake = this.getVideoEditTake(sceneId, sceneName);
  }

  addVideoLayerToTakeAsync(videoPositionId: string): Promise<IVideoLayerInDTO> {
    return this.recorderManager.addVideoLayerToTakeAsync(videoPositionId);
  }

  appendChunkToVideoLayerAsync(chunk: Blob): Promise<void> {
    return this.recorderManager.appendChunkToVideoLayerAsync(
      chunk,
      this.videoLayers[0].id
    );
  }

  setMediaRecorder(stream: MediaStream): MediaRecorder {
    return this.recorderManager.setMediaRecorder(stream);
  }

  // Override toJSON to exclude recorderManager
  toJSON() {
    const {
      recorderManager,
      videoLayerApi,
      profileService,
      videoLayerDBController,
      videoEditTake,
      ...rest
    } = this;
    return rest;
  }

  clone(projectId: string): ITake {
    // Custom serialization to avoid circular references
    const serializedTake = JSON.stringify(this.toJSON());

    // Parse the serialized string back into a new object
    const clonedObject = JSON.parse(serializedTake) as Take;
    clonedObject.videoLayerApi = this.videoLayerApi;
    clonedObject.profileService = this.profileService;
    clonedObject.videoLayerDBController = this.videoLayerDBController;
    clonedObject.videoEditTake = this.getVideoEditTake(
      this.sceneId,
      this.sceneName
    );
    // Reassign the recorderManager with a new instance
    clonedObject.recorderManager = new TakeRecorderManager(
      projectId,
      this.sceneId,
      clonedObject.id,
      this.videoLayerApi,
      this.profileService,
      null
    );

    return clonedObject;
  }

  getVideoEditTake(sceneId: string, sceneName: string) {
    const newLottieComposedConfigs: IComposeAndPlayOrNot = JSON.parse(
      JSON.stringify(this.lottieComposedConfigs)
    );
    const takeWithComposeConfigs: ITakeWithLottieComposed = {
      ...this,
      title: this.title,
      lottieComposedConfigs: newLottieComposedConfigs,
    };
    const trims: ITrimLayers = {
      videoTrims: {
        end: 0,
        start: 0,
      },
      lottieTrims: {
        end: 0,
        start: 0,
      },
    };
    const localTakeToList = new VideoEditTake(
      takeWithComposeConfigs,
      {},
      sceneId,
      sceneName,
      trims
    );
    return localTakeToList;
  }

  get mediaRecorderOptions(): MediaRecorderOptions {
    return this.recorderManager.mediaRecorderOptions;
  }

  get currentBaseMimetype(): string {
    return this.recorderManager.currentBaseMimetype;
  }
}
