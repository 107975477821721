// ai-text.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { VoiceRecognitionService } from '../../../../services/voice-recognition.service';
import { ITextPortion } from '../../../../models/Teleprompter';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-ai-text',
  templateUrl: './ai-text.component.html',
  styleUrls: ['./ai-text.component.scss'],
})
export class AiTextComponent implements OnInit {
  @Input({ alias: 'aiMode', required: false }) aiMode: boolean;
  textAi: ITextPortion[] = [];

  private destroy$ = new Subject<void>();

  constructor(public voiceRecognition: VoiceRecognitionService) {
  }

  ngOnInit(): void {
    this.voiceRecognition.notifySplitTextProcessed
      .pipe(takeUntil(this.destroy$))  // Automatically unsubscribes on destroy
      .subscribe((processedText) => {
        if (!processedText) {
          return;
        }
        this.textAi = Array.from(processedText.ai_text.values());
      });
  }
}
