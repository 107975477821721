import { IStagePositionsExtended, MimeTypeEnum } from 'src/app/models/defines';

export enum EnhanceTypeEnum {
  AUDIO_ONLY_CLEAN = 'audio-only-clean',
  AUDIO_AND_VIDEO_CLEAN = 'video-audio-clean',
  VIDEO_SEGMENTATION = 'video-segmentation',
  VIDEO_BLUR = 'video-blur',
}
export interface IVideoLayerUploadUrlRequestBody {
  width: number;
  height: number;
  mimeType: MimeTypeEnum;
  videoPositionId: string | null;
}

export interface IVideoLayerEnhanceRequestBody {
  videoLayerIds: string[];
  editId: string;
}
