import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { VoiceRecognitionService } from '../../../services/voice-recognition.service';
import { ColorPalettesService } from '../../../services/utils/color-palettes.service';
import { MatDialog } from '@angular/material/dialog';
import { ITextPortion } from '../../../models/Teleprompter';
import { IUserSettings } from '../../../models/teleprompter/prompter-settings-model';
import { DirectorCommand } from '../../../models/defines';
import { CopywriterService } from '../../../services/show/copywriter.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrompterSyncronizerService } from '../../../services/prompter-syncronizer.service';
import { v4 as uuidv4 } from 'uuid';
import { ApplyPrompterTextComponent } from '../../dialogs/apply-prompter-text/apply-prompter-text.component';

enum ReadModeEnum {
  AI_MODE = 'ai',
  DUMMY_MODE = 'dummy'
}

@Component({
  selector: 'app-teleprompter',
  templateUrl: './teleprompter.component.html',
  styleUrls: ['./teleprompter.component.scss'],
})
export class TeleprompterComponent implements OnInit, OnDestroy {
  readModeEnum: ReadModeEnum;
  dummyReadMode: boolean = false;
  textAlignments = {
    LEFT: { align: 'left', icon: 'format_align_left' },
    RIGHT: { align: 'right', icon: 'format_align_right' },
    Center: { align: 'center', icon: 'format_align_center' },
  };
  displayNotes = false;
  prompterID = uuidv4();
  @Output('finished-walkthrough-step') finishedWalkthroughStep =
    new BehaviorSubject<boolean>(false);

  @ViewChild('scroller') scroller: ElementRef<HTMLTextAreaElement>;
  @ViewChild('scrollerWrap') scrollerWrap: ElementRef<HTMLTextAreaElement>;
  @Input() hosted = false;

  @Output('text-changed')
  textChanged = new EventEmitter<string>();

  notes = '';
  NOTES_LOCALSTORAGE_KEY = 'prompterNotes';
  @Input('backgroundColor')
  backgroundColor = '#222';
  manualText = '';
  previousText: string = '';
  @Input('textColor')
  textColor = '#fff';

  @Input('fontSize')
  fontSize = 30;
  speed = 7;
  margin = 0;
  playing = false;
  scrollInterval;

  @Input('aiMode')
  aiMode = true;
  masterUp = false;
  notificationOpen = false;
  private onDestroy$: Subject<boolean> = new Subject();
  textAi: ITextPortion[] = [];
  isToggle: boolean = true;
  teleprompterSettings: IUserSettings;
  promptSettingsLSKey = 'userSettings';

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public copywriter: CopywriterService,
    private _snackBar: MatSnackBar,
    public prompterSync: PrompterSyncronizerService,
    public voiceRecognition: VoiceRecognitionService,
    public colorPalettesService: ColorPalettesService,
  ) {
    // this.voiceRecognition.init()
  }

  ngOnInit(): void {
    this.loadSettings();
    this.subscribeToPromptSync();

    this.voiceRecognition.notifyScrollTeleprompter
      .pipe(takeUntil(this.onDestroy$)) // Clean up the subscription on component destroy
      .subscribe((word) => {
        if (!word) {
          return;
        } else {
          const scrollIndex = word.index + 4;
          const scrollWord = document.getElementById(scrollIndex.toString());
          if (scrollWord) {
            scrollWord.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }
      });
    /// Connect
    if (!this.hosted) {
      this.prompterSync.masterUp.subscribe((status) => {
        // console.log('Changing Master to  ' + status);
        this.masterUp = status;
        this.cdRef.detectChanges();
      });
      this.notifyUp();

      this.prompterSync.grabPrompterText.subscribe((messageData) => {

        if (messageData && !this.notificationOpen) {
          this.notificationOpen = true;
          const applyPrompterTextDialog = this.dialog.open(
            ApplyPrompterTextComponent,
            {
              disableClose: false,
              panelClass: 'padding-modal',
            },
          );

          applyPrompterTextDialog.afterClosed().subscribe((approval) => {
            this.notificationOpen = false;
            // console.log('End Live Dialog result:', approval);
            // If the user approved, end live
            if (approval) {
              if (messageData.version && messageData.text) {
                this.manualText = messageData.text;
              }
            }
          });
        }
      });
    } else {
      /// For the hosted prompter
      this.prompterSync.publishPrompterText.subscribe((message) => {
        if (message?.text) {
          this.manualText = message.text;
          this.copywriter.copy.next(message.text);
        }
      });
    }
  }

  publish() {
    this.prompterSync.publish(this.prompterID, 'Version1', this.manualText);
    this._snackBar.open('🥳 Script published to guests!', 'Dismiss', {
      duration: 3000,
    });

    this.setCopy(this.manualText, false);
  }

  notifyUp() {
    this.prompterSync.connectSlave(this.prompterID);
  }

  resetTeleprompterState() {
    this.rewindToStart('smooth');
    /// Only if we play we reset the button
    if (this.playing) {
      this.playPause(false);
    }
  }

  saveSettings() {
    localStorage.setItem(this.promptSettingsLSKey, JSON.stringify(this.teleprompterSettings));
  }

  loadSettings() {
    const savedSettings = localStorage.getItem(this.promptSettingsLSKey);
    if (savedSettings) {
      const settings: IUserSettings = JSON.parse(savedSettings);
      const isChanged = this.checkTeleprompterSettings(settings);
      if (isChanged) {
        this.teleprompterSettings = settings;
        this.saveSettings();
        return;
      } else {
        this.teleprompterSettings = settings;
      }
    } else {
      this.setDefaultSettings();
    }
  }

  checkTeleprompterSettings(settingsObj: IUserSettings): boolean {
    const settingExample: IUserSettings = {
      margin: 0,
      speed: 7,
      fontSize: 24,
      textAlignment: { align: 'left', icon: 'format_align_left' },
      flippedX: false,
      flippedY: false,
      backgroundColor: '#222',
      textColor: '#fff',
      voiceRecognitionMode: true,
    };

    let isModified = false; // Flag to track if any modifications were made

    // Iterate through the keys of settingExample
    Object.keys(settingExample).forEach((key) => {
      const settingKey = key;

      // Check if the key is missing or the value is null in settingsObj
      if (!settingsObj[settingKey]) {
        // If the key is missing or the value is null, add the value from settingExample
        settingsObj[settingKey] = settingExample[settingKey];
        isModified = true; // Set the flag to true since we modified settingsObj
      }
    });

    return isModified; // Return true if any modifications were made, false otherwise
  }

  setDefaultSettings() {
    this.teleprompterSettings = {
      margin: 0,
      speed: 7,
      fontSize: 24,
      textAlignment: { align: 'left', icon: 'format_align_left' },
      flippedX: false,
      flippedY: false,
      backgroundColor: '#222',
      textColor: '#fff',
      voiceRecognitionMode: true,
    };
    this.saveSettings();
  }

  onTextBlur(event: Event): void {
    const inputElement = event.target as HTMLTextAreaElement;
    const newText = inputElement.value;

    if (newText !== this.previousText) {
      this.manualText = newText;
      this.previousText = newText;
      // this.sendTextToServer(newText);
      this.textChanged.emit(this.previousText);
    }
  }

  saveNotes() {
    if (this.notes) {
      localStorage.setItem(this.NOTES_LOCALSTORAGE_KEY, this.notes);
    }
  }

  startService() {
    this.voiceRecognition.start();
  }

  stopService() {
    this.voiceRecognition.stop();
  }

  toggleTextAlign() {
    const textAlignObj = this.teleprompterSettings.textAlignment;
    if (textAlignObj.align === 'left') {
      textAlignObj.align = 'center';
      textAlignObj.icon = 'format_align_center';
    } else if (textAlignObj.align === 'center') {
      textAlignObj.align = 'right';
      textAlignObj.icon = 'format_align_right';
    } else if (textAlignObj.align === 'right') {
      textAlignObj.align = 'left';
      textAlignObj.icon = 'format_align_left';
    }
  }

  rewindToStart(behavior: ScrollBehavior) {
    if (this.scrollerWrap?.nativeElement) {
      this.scrollerWrap.nativeElement.scrollTo({ behavior: behavior, top: 0 });
    }
  }

  flip() {
  }

  setCopy(copy, showSnackbar = true) {
    this.copywriter.setCopy(copy);
    if (showSnackbar) {
      this._snackBar.open('Copy Saved', 'Dismiss', { duration: 2000 });
    }
  }

  displayNotesChange(event) {
    this.displayNotes = !this.displayNotes;
  }

  /**
   * Toggles play and pause states with different behaviors for AI and Manual modes.
   *
   * @param toPlayOrPause - Boolean indicating whether to play (true) or pause (false).
   */
  playPause(toPlayOrPause: boolean): void {
    this.playing = toPlayOrPause;
    if (this.teleprompterSettings.voiceRecognitionMode) {
      this.handleAiMode();
    } else {
      this.handleManualMode();
    }
  }

  private handleAiMode(): void {
    if (this.playing) {
      this.startVoiceRecognition();
    } else {
      this.stopVoiceRecognition();
      this.rewindToStart('smooth');
    }
  }

  private startVoiceRecognition(): void {
    this.voiceRecognition.start();
  }

  private stopVoiceRecognition(): void {
    this.voiceRecognition.stop();
  }

  /**
   * Handles the play/pause behavior in Manual mode.
   */
  private handleManualMode(): void {
    if (this.playing) {
      this.startManualScrolling();
    } else {
      this.stopManualScrolling();
      this.rewindToStart('smooth');
    }
  }

  /**
   * Starts the manual scrolling process.
   */
  private startManualScrolling(): void {
    this.rewindToStart('auto');
    this.stopManualScrolling();
    this.scrollInterval = setInterval(() => {
      this.scrollContent();
    }, 100);
  }

  /**
   * Stops the manual scrolling process.
   */
  private stopManualScrolling(): void {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }

  /**
   * Scrolls the content smoothly based on the current speed.
   */
  private scrollContent(): void {
    const linesPerSecond = this.teleprompterSettings.speed / 4;  // lines per second (already calculated or set)
    const fontSize = this.teleprompterSettings.fontSize;         // font size (in pixels)
    // Pixels per second = lines per second * font size
    console.log(linesPerSecond);
    const pixelsPerSecond = linesPerSecond * fontSize;
    // Since the scroll interval is 100ms (1/10th of a second), we scroll 1/10th of the pixels per second
    const pixelsPerInterval = pixelsPerSecond > 0 ? Math.max(1, pixelsPerSecond / 10) : 0;
    // Now perform the scroll
    this.scrollerWrap.nativeElement.scrollTo({
      behavior: 'smooth',
      top: this.scrollerWrap.nativeElement.scrollTop + pixelsPerInterval,
    });
  }


  removeEvent(
    text: ITextPortion,
    eventType: DirectorCommand = DirectorCommand.NEXT,
  ) {
    // this.voiceRecognition.addEventAfterWord(text, eventType)
    const word = this.voiceRecognition.splitText.get(text.uuid);

    if (word.events?.length) {
      word.events = word.events.filter((obj) => {
        return obj !== eventType;
      });
      text.events = word.events;
    }
  }

  createEvent(
    text: ITextPortion,
    eventType: DirectorCommand = DirectorCommand.NEXT,
  ) {
    // this.voiceRecognition.addEventAfterWord(text, eventType)
    let word = this.voiceRecognition.splitText.get(text.uuid);

    word.events = word.events ?? [];
    word.events.push(eventType);
    // To Keep thing tidy
    text.events = word.events;
    //
    // let foundInWordsList = this.voiceRecognition.splitTextOnlyWords.find(x => x.uuid === text.uuid);
    // if (foundInWordsList) {
    //   foundInWordsList.events = text.events;
    // }
    console.log(this.voiceRecognition.splitText.values());
  }

  openPrompterInANewWindow() {
    window.open('/prompter', 'newwindow', 'width=800,height=600');
  }

  private subscribeToPromptSync() {
    this.prompterSync.currentPrompterText$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((newText) => {
        if (this.manualText === newText) {
          return;
        }
        this.manualText = newText ?? '';
        this.previousText = this.manualText;
        this.copywriter.copy.next(this.manualText);
      });

    this.prompterSync.requestToStartTeleprompt
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((toStart) => {
        this.playPause(toStart);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }
}
