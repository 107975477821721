import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectStatusEnum } from '../../../models/defines';
import {
  DASHBOARD_URL,
  PANEL_OUTLET_URL,
} from '../../../pages/private/host/services/shared/constants/client/dashboard/dashboard.routes';
import { PLANNING_URL } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/project-planning.routes';
import { STUDIO_URL } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/studio.routes';
import { EDIT_ROOM_URL } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/edit-room.routes';
import { EXPORT_PAGE } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/export-routes';

@Injectable({
  providedIn: 'root',
})
export class ProjectRoutingService {
  constructor(private router: Router) {}

  // Mapping of project phases to their respective URL segments
  private phaseToUrlMap: { [key in ProjectStatusEnum]: string } = {
    [ProjectStatusEnum.PLANNING]: PLANNING_URL,
    [ProjectStatusEnum.RECORDING]: STUDIO_URL,
    [ProjectStatusEnum.EDIT_ROOM]: EDIT_ROOM_URL,
    [ProjectStatusEnum.EXPORTING]: EXPORT_PAGE,
    [ProjectStatusEnum.DONE]: EXPORT_PAGE,
  };

  goToAsync(goToPhase: ProjectStatusEnum, projectId: string) {
    if (!projectId) {
      console.warn('Project ID is required for navigation.');
      return;
    }

    const phaseUrl = this.phaseToUrlMap[goToPhase];
    if (!phaseUrl) {
      // Handle unknown phase
      console.error(`Unknown project phase: ${goToPhase}`);
      return;
    }

    const projectOutletPath = ['project', phaseUrl, projectId];

    return this.router.navigate([
      DASHBOARD_URL,
      {
        outlets: {
          [PANEL_OUTLET_URL]: projectOutletPath,
        },
      },
    ]);
  }
}
