import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/guards/authentication.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsNotifierService } from '../../../services/utils/analytics-notifier.service';
import { FeedbackEnum, ROLE } from '../../../models/defines';
import { FeedbacksService } from 'src/app/services/utils/feedbacks.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import { combineLatest, Subscription } from 'rxjs';
import { RecordingProgressService } from 'src/app/services/studio/recording-progress.service';
import { ToastrService } from 'ngx-toastr';
import { FeedbackDialogComponent } from 'src/app/components/dialogs/feedback-dialog/feedback-dialog.component';
import { User } from 'src/app/models/user';
import { IntercomService } from 'src/app/services/intercom.service';
import { UserService } from 'src/app/services/api/auth/user.service';
import { FontsStoreService } from '../../../services/state-management/configs/fonts-store.service';
import { WelcomeDialogComponent } from 'src/app/components/dialogs/welcome-dialog/welcome-dialog.component';
import { ProjectMainEventsService } from '../../../services/socket/project/project-main-events.service';
import { PANEL_OUTLET_URL } from '../host/services/shared/constants/client/dashboard/dashboard.routes';

export const WELCOME_DIALOG_SHOWN_KEY = 'welcomeDialogShown';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  PANEL_OUTLET_URL = PANEL_OUTLET_URL;
  public selectedTabIndex = 1;
  fullScreen = false;
  sessionTimer: any = null;
  sessionTimeInMinutes: number = 0;
  isMobileUser: boolean;
  private subscriptions: Subscription = new Subscription();
  user: User;
  private isFeedbackToastShown = false; // Flag to track if feedback toastr has been shown
  private welcomeDialogRef: MatDialogRef<WelcomeDialogComponent>;

  constructor(
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public gtmService: AnalyticsNotifierService,
    private feedbacksService: FeedbacksService,
    public profileService: ProfileService,
    private recordingProgressService: RecordingProgressService,
    private toastr: ToastrService,
    private intercomService: IntercomService,
    private userService: UserService,
    /// Do not remove font store service, we use it to initialize fonts the service's constructor
    private fontsStoreService: FontsStoreService
  ) {
    this.isMobileUser = this.userService.isMobileUser();
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   if (localStorage.getItem(WELCOME_DIALOG_SHOWN_KEY) === 'false') {
  //     window.location.reload();
  //   }
  // }
  ngAfterViewInit(): void {
    this.profileService.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        if (!this.isMobileUser) {
          this.intercomService.init(user);
        }
      }
    });
  }

  ngOnInit(): void {
    this.showFeedbackDialogAfterTimeout();

    //open welcome popup to any user only once.

    const welcomeDialogIsShown = localStorage.getItem(WELCOME_DIALOG_SHOWN_KEY);
    if (!welcomeDialogIsShown) {
      this.dialog
        .open(WelcomeDialogComponent, {
          disableClose: false,
          panelClass: 'welcome-dialog',
          maxHeight: '90vh',
          // data: { showVideo: true },
        })
        .afterClosed()
        .subscribe((res) => {
          localStorage.setItem(WELCOME_DIALOG_SHOWN_KEY, 'true');
          this.welcomeDialogRef = null;
        });
    }
    // Find out if we want to hide the walkthrough dialog
    // const hideWalkThrough =
    //   localStorage.getItem(WalkthroughComponent.LOCALSTORAGE_KEY) === 'true';
    // console.log('Hide walkthrough screen', hideWalkThrough);
    // if (!hideWalkThrough) {
    //   /// Open the walkthrough dialog, when finished, start the stream settings dialog
    //   const walkthroughDialog = this.dialog.open(WalkthroughComponent, {
    //     disableClose: true,
    //     panelClass: 'walkthrough-dialog',
    //   });
    //   walkthroughDialog.afterClosed().subscribe((result) => {});
    // }
  }

  showFeedbackDialogAfterTimeout() {
    const isFeedbackSubmited =
      this.feedbacksService.getIsFeedbackSucmitedFromLocaStorage();
    this.sessionTimer = setInterval(() => {
      this.sessionTimeInMinutes++;
      this.profileService.sessionTimeInMinutes$.next(this.sessionTimeInMinutes);
    }, 60000);

    if (!this.isMobileUser) {
      this.subscriptions.add(
        combineLatest([
          this.profileService.sessionTimeInMinutes$,
          this.recordingProgressService.isRecordingInProgress$,
        ]).subscribe(([sessionTimeInMinutes, isRecordingInProgress]) => {
          if (
            sessionTimeInMinutes === 4 &&
            !isRecordingInProgress &&
            isFeedbackSubmited !== 'true' &&
            !this.isFeedbackToastShown // Check if feedback has not been shown
          ) {
            this.isFeedbackToastShown = true; // Set the flag to true
            if (this.toastr) {
              this.toastr.clear();
            }
            const toastRef = this.toastr.info(
              '30 seconds to be our MVP!<br> Help us out with your honest feedback!<br><b><u> Click here<b><u>',
              'Feedback',
              {
                disableTimeOut: false,
                tapToDismiss: false,
                closeButton: true,
                timeOut: 20000,
              }
            );
            // this.feedbacksService.setFeedbackSubmitedToLocalStorage('true');

            toastRef.onTap.subscribe(() => {
              const feedbackDialog = this.dialog.open(FeedbackDialogComponent, {
                width: '500px',
                height: '450px',
                data: {
                  feedbackFormId: FeedbackEnum.GENERAL_FEEDBACK,
                },
              });

              feedbackDialog.afterClosed().subscribe((res) => {
                if (res === true) {
                  this.toastr.clear();
                }
              });
            });
          }
        })
      );
    }
  }

  selectedTabChange(event) {}

  ngOnDestroy(): void {
    this.intercomService.shutdown();
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
    }
    this.subscriptions.unsubscribe();

    if (this.welcomeDialogRef) {
      this.welcomeDialogRef.close();
    }
  }

  protected readonly ROLE = ROLE;
}
