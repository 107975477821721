import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CopywriterService {
    LOCALSTORAGE_KEY = 'SFL_COPY';
    copy = new BehaviorSubject<string>('');
    topicsAndHeaders = new BehaviorSubject<{
        topics: string[];
        headers: string[];
    }>(null);
    subtopics = new BehaviorSubject<string[]>(null);
    suggestions = new BehaviorSubject<string[]>(null);
    videoTitle = new BehaviorSubject<string>(null);
    userPrompt = new BehaviorSubject<string>('My video on Shuffll');

    constructor() {
        this.getCopy();
    }

    setCopy(copy: string) {
        if (!copy) {
            return;
        }
        localStorage.setItem(this.LOCALSTORAGE_KEY, copy);
        this.copy.next(copy);
    }


    getCopy() {
        if (!this.copy.value) {
            this.copy.next(localStorage.getItem(this.LOCALSTORAGE_KEY) ?? '');
        }
        return this.copy.value;
    }
}
