<div *ngIf="teleprompterSettings"
     class="prompter-wrapper"
     [style.background-color]="teleprompterSettings.backgroundColor"
     [class.standalone]="!hosted"
     [class.hosted]="hosted"
     [class.ai-mode]="teleprompterSettings.voiceRecognitionMode"
     [class.manual-mode]="!teleprompterSettings.voiceRecognitionMode">
  <div
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    *ngIf="!hosted">
    <div class="title-bar row-centered row-spacebetween">
      <div class="row-centered">
        <mat-icon>drag_indicator</mat-icon>
        Teleprompter
      </div>

      <div>
        <button mat-icon-button class="small-icon-button" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="shuffll-card gradient-primary" *ngIf="displayNotes">
    <!--    <div>-->
    <!--      <h4>-->
    <!--        Notes-->
    <!--      </h4>-->
    <!--    </div>-->
    <div>
      <mat-form-field
        id="notes"
        (focusout)="saveNotes()"
        class="notes-text-area">
        <mat-label>Notes</mat-label>
        <textarea
          matInput
          [(ngModel)]="notes"
          name="notes"
          spellcheck="false"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </div>
  </div>

  <!--  <mat-menu #wordMenu="matMenu">-->
  <!--    <ng-template matMenuContent let-word="word">-->
  <!--      <button mat-menu-item (click)="voiceRecognition.jumpToWord(word)">-->
  <!--        Jump here-->
  <!--      </button>-->
  <!--      <button-->
  <!--        mat-menu-item-->
  <!--        *ngIf="!word.events?.length"-->
  <!--        (click)="createEvent(word)">-->
  <!--        Add Scene Trigger-->
  <!--      </button>-->
  <!--      <button-->
  <!--        mat-menu-item-->
  <!--        *ngIf="word.events?.length"-->
  <!--        (click)="removeEvent(word)">-->
  <!--        Remove Scene Trigger-->
  <!--      </button>-->
  <!--    </ng-template>-->
  <!--  </mat-menu>-->

  <div
    class="scroller-wrap"
    id="scroller-wrap"
    [class.streched]="!displayNotes"
    [class.transparent-scrollbar]="hosted"
    joyrideStep="walkthrough-teleprompter"
    title="Meet the AI teleprompter"
    text="Here are your script suggestions. The AI teleprompter will detect and follow your speech.
You can use triggers on each word to switch scenes and activate effects."
    stepPosition="right"
    (next)="finishedWalkthroughStep.next(true)"
    #scrollerWrap
    [class.flipped-x]="teleprompterSettings.flippedX"
    [class.flipped-y]="teleprompterSettings.flippedY">

    <p>
      <mat-slide-toggle [(ngModel)]="teleprompterSettings.voiceRecognitionMode" (change)="saveSettings()"
                        class="top-right-container"
                        labelPosition="after"
                        color="primary">Voice activation
      </mat-slide-toggle>
    </p>

    <!-- Regular Mode -->
    <div
      [style.color]="teleprompterSettings.textColor"
      [style.font-size]="teleprompterSettings.fontSize + 'px'"
      [style.line-height]="teleprompterSettings.fontSize * 1.5 + 'px'"
      [style.text-align]="teleprompterSettings.textAlignment.align"
      [style.padding-left]="teleprompterSettings.margin + '%'"
      [style.padding-right]="teleprompterSettings.margin + '%'">

      <app-dummy-text
        *ngIf="!teleprompterSettings.voiceRecognitionMode"
        id="scroller"
        [dummyReadMode]="dummyReadMode"
      >
      </app-dummy-text>

      <app-ai-text
        *ngIf="teleprompterSettings.voiceRecognitionMode"
        [aiMode]="aiMode"
      >
      </app-ai-text>
    </div>
  </div>

  <div class="toolbar-area">
    <div class="tab-title">
      <div class="top-right">
        <button
          *ngIf="!teleprompterSettings.voiceRecognitionMode"
          [class.small-icon-button]="hosted"
          mat-mini-fab
          class="cool-button gray play-pause"
          color="secondary"
          [class.pause]="playing"
          cdkFocusInitial
          (click)="playPause(!playing)">
          <mat-icon>{{ playing ? 'pause' : 'play_arrow' }}</mat-icon>
        </button>
        <!--                <button-->
        <!--                  *ngIf="aiMode"-->
        <!--                  [class.small-icon-button]="hosted"-->
        <!--                  mat-mini-fab-->
        <!--                  class="cool-button gray play-pause"-->
        <!--                  color="secondary"-->
        <!--                  [class.pause]="playing"-->
        <!--                  cdkFocusInitial-->
        <!--                  (click)="startService()">-->
        <!--                  <mat-icon>{{ playing ? 'pause' : 'play_arrow' }}</mat-icon>-->
        <!--                </button>-->
        <button
          [class.small-icon-button]="hosted"
          mat-mini-fab
          class="cool-button gray"
          color="secondary"
          (click)="rewindToStart('smooth')"
          matTooltip="Start Over">
          <mat-icon>restart_alt</mat-icon>
        </button>
        <button
          [class.small-icon-button]="hosted"
          mat-mini-fab
          class="cool-button gray"
          color="secondary"
          [matMenuTriggerFor]="mainMenu">
          <mat-icon>settings</mat-icon>
        </button>

        <!-- <button
          class="cool-button gray speed-slider"
          color="secondary"
          matTooltip="Speed"
          [class.small-icon-button]="hosted"
          mat-mini-fab
          [matMenuTriggerFor]="speedMenu"
          [disabled]="aiMode"
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
          step="1"
          min="0"
          max="50"
          aria-label="speed units">
          <mat-icon>speed</mat-icon>
        </button> -->
      </div>
    </div>

    <div class="tab-title-buttons">
      <!--      <button mat-icon-button class="cool-button gray" *ngIf="!hosted" (click)="publish()" matTooltip="Publish"-->
      <!--              [disabled]="!masterUp">-->
      <!--        <mat-icon>publish</mat-icon>-->
      <!--      </button>-->

      <!--      <button mat-icon-button (click)="setCopy(manualText)" matTooltip="Save Copy">-->
      <!--        <mat-icon>save</mat-icon>-->
      <!--      </button>-->
      <button
        mat-mini-fab
        class="cool-button gray"
        color="secondary"
        *ngIf="false"
        (click)="openPrompterInANewWindow()"
        matTooltip="Open Prompter Window">
        <mat-icon>tab</mat-icon>
      </button>
      <!--      <button mat-icon-button *ngIf="hosted" (click)="togglleEditText()" matTooltip="Edit Copy">-->
      <!--        <mat-icon>edit</mat-icon>-->
      <!--      </button>-->
    </div>
    <mat-menu #mainMenu="matMenu" class="prompter-menu">


      <button mat-menu-item [matMenuTriggerFor]="marginMenu">
        <mat-icon>document_scanner</mat-icon>
        Margins: <b>{{ teleprompterSettings.margin }}</b>
      </button>

      <button *ngIf="!teleprompterSettings.voiceRecognitionMode" mat-menu-item [matMenuTriggerFor]="speedMenu">
        <mat-icon>speed</mat-icon>
        Speed: <b>{{ teleprompterSettings.speed }}</b>
      </button>

      <button mat-menu-item [matMenuTriggerFor]="fontSizeMenu">
        <mat-icon>format_size</mat-icon>
        Font Size: <b>{{ teleprompterSettings.fontSize }}</b>
      </button>

      <mat-divider></mat-divider>

      <div
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        class="menu-style-toolbar">
        <button mat-icon-button (click)="toggleTextAlign(); saveSettings()" matTooltip="Align">
          <mat-icon>{{ teleprompterSettings.textAlignment.icon }}</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="$event.stopPropagation(); teleprompterSettings.flippedX = !teleprompterSettings.flippedX; saveSettings()"
          (keydown)="$event.stopPropagation()"
          matTooltip="Mirror Horizontally">
          <mat-icon>swap_horiz</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="$event.stopPropagation(); teleprompterSettings.flippedY = !teleprompterSettings.flippedY; saveSettings()"
          (keydown)="$event.stopPropagation()"
          matTooltip="Mirror Vertically">
          <mat-icon>swap_vert</mat-icon>
        </button>

        <div
          class="color-picker-container"
          [(colorPicker)]="teleprompterSettings.backgroundColor"
          [style.background]="teleprompterSettings.backgroundColor"
          [cpDialogDisplay]="'popup'"
          (colorPickerChange)="saveSettings()">
        </div>
        <div
          class="color-picker-container"
          style="margin-left: 7px"
          [(colorPicker)]="teleprompterSettings.textColor"
          [style.background]="teleprompterSettings.textColor"
          (colorPickerChange)="saveSettings()">
          T
        </div>
      </div>

      <mat-menu #speedMenu="matMenu">
        <mat-slider
          class="mat-menu-speed-slider"
          (click)="$event.stopPropagation(); saveSettings();"
          (keydown)="$event.stopPropagation()"
          thumbLabel
          step="1"
          min="0"
          max="50"
          aria-label="speed units">
          <input matSliderThumb [(ngModel)]="teleprompterSettings.speed" />
        </mat-slider>
      </mat-menu>

      <mat-menu #marginMenu="matMenu" class="fix-padding-menu">
        <mat-slider
          (click)="$event.stopPropagation(); saveSettings()"
          (keydown)="$event.stopPropagation()"
          thumbLabel
          step="2"
          min="0"
          max="50"
          aria-label="margin units">
          <input matSliderThumb [(ngModel)]="teleprompterSettings.margin" name="margin" />
        </mat-slider>
      </mat-menu>

      <mat-menu #fontSizeMenu="matMenu" class="fix-padding-menu">
        <mat-slider
          (click)="$event.stopPropagation(); saveSettings();"
          (keydown)="$event.stopPropagation()"
          thumbLabel
          step="2"
          min="12"
          max="120"
          aria-label="units">
          <input matSliderThumb [(ngModel)]="teleprompterSettings.fontSize" />
        </mat-slider>
      </mat-menu>
    </mat-menu>
  </div>
</div>
