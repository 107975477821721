import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  getAppendChunkToVideoLayerUrlPOST,
  getAudioCleanVideoLayersPOST,
  getSegmentationVideoLayersPOST,
  getVideoLayerPOST,
} from 'src/app/constants/private/urls/projects/video-layer.urls';
import { MimeTypeEnum } from 'src/app/models/defines';
import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { IVideoLayerInDTO } from 'src/app/models/project/take/layers/video-model';
import {
  IVideoLayerEnhanceRequestBody,
  IVideoLayerUploadUrlRequestBody,
} from 'src/app/services/http-models/project/video-layer/video-layer-http-request-model';

@Injectable({
  providedIn: 'root',
})
export class VideoLayerApiService {
  constructor(private http: HttpClient) {}

  public postVideoLayer$(
    projectId: string,
    sceneId: string,
    takeId: string,
    mimetype: MimeTypeEnum,
    videoPositionId: string,
    width: number,
    height: number
  ) {
    if (!projectId || !sceneId || !takeId || !mimetype || !width || !height) {
      throw new MissingArgumentsError(
        `Could not get video layer upload url because one of the arguments is null.`
      );
    }
    const videoLayerUploadUrl = getVideoLayerPOST(projectId, sceneId, takeId);
    const body: IVideoLayerUploadUrlRequestBody = {
      mimeType: mimetype,
      height: height,
      width: width,
      videoPositionId: videoPositionId,
    };
    return this.http.post<IVideoLayerInDTO>(videoLayerUploadUrl, body);
  }

  public appendChunkToVideoLayer$(
    projectId: string,
    sceneId: string,
    takeId: string,
    videoLayerId: string,
    chunk: Blob
  ) {
    if (!projectId || !sceneId || !takeId || !videoLayerId || !chunk) {
      throw new MissingArgumentsError(
        `Could not get append chunk to video layer because one of the arguments is null.`
      );
    }

    const urlToAppendChunk = getAppendChunkToVideoLayerUrlPOST(
      projectId,
      sceneId,
      takeId,
      videoLayerId
    );

    const formData = new FormData();
    formData.append('chunk', chunk);
    return this.http.post<{ message: string }>(urlToAppendChunk, formData);
  }

  public audioCleanVideoLayers$(
    projectId: string,
    editId: string,
    sceneId: string,
    takeId: string,
    videoLayerIds: string | string[]
  ) {
    if (!projectId || !editId || !sceneId || !takeId || !videoLayerIds) {
      throw new MissingArgumentsError(
        `Could not get append chunk to video layer because one of the arguments is null.`
      );
    }
    if (!Array.isArray(videoLayerIds)) {
      videoLayerIds = [videoLayerIds];
    }
    const body: IVideoLayerEnhanceRequestBody = {
      videoLayerIds: videoLayerIds,
      editId: editId,
    };
    const urlToEnhance = getAudioCleanVideoLayersPOST(
      projectId,
      sceneId,
      takeId
    );
    return this.http.post<{ message: string }>(urlToEnhance, body);
  }

  public segmentationCleanVideoLayers$(
    projectId: string,
    editId: string,
    sceneId: string,
    takeId: string,
    videoLayerIds: string | string[]
  ) {
    if (!projectId || !editId || !sceneId || !takeId || !videoLayerIds) {
      throw new MissingArgumentsError(
        `Could not get append chunk to video layer because one of the arguments is null.`
      );
    }
    if (!Array.isArray(videoLayerIds)) {
      videoLayerIds = [videoLayerIds];
    }
    const body: IVideoLayerEnhanceRequestBody = {
      videoLayerIds: videoLayerIds,
      editId: editId,
    };
    const urlToEnhance = getSegmentationVideoLayersPOST(
      projectId,
      sceneId,
      takeId
    );
    return this.http.post<{ message: string }>(urlToEnhance, body);
  }
}
