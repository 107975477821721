import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import { IProjectInDTO } from '../../../models/project-model';
import { FunctionsHelperService } from '../../../services/functions-helper.service';
import { Router } from '@angular/router';
import { ILayout, ProjectStatusEnum } from 'src/app/models/defines';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { ArtDirectorService } from 'src/app/services/art-director.service';

import { LottiePlayerService } from 'src/app/services/lottie-player.service';
import { AnimationItem } from 'lottie-web';
import { v4 as uuidv4 } from 'uuid';
import { ProjectRoutingService } from '../../../services/project/routes/project-routing.service';

interface IExtendedProjectInDTO extends IProjectInDTO {
  iconLayout?: ILayout;
}

@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit, OnChanges {
  ProjectStatusEnum = ProjectStatusEnum;
  personalizedDynamics: IDynamicLottieChange[];
  animationItems = new Map<string, AnimationItem>();

  @Input('project') project: IExtendedProjectInDTO;

  constructor(
    private readonly router: Router,
    public config: ConfigurationService,
    public helperFunctions: FunctionsHelperService,
    private artDirector: ArtDirectorService,
    private lottiePlayer: LottiePlayerService,
    private projectRoutingService: ProjectRoutingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project']) {
      if (!this.project) return;
      const id = uuidv4();
      const iconLayout: ILayout = {
        _id: id,
        lottiePath: this.project.icon,
      };
      this.project.iconLayout = iconLayout;
    }
  }

  ngOnInit(): void {
    this.personalizedDynamics =
      this.artDirector.extractDynamicLottieByUserBranding();
  }

  goToExports(projectId: string) {
    if (!this.project) return;

    this.projectRoutingService.goToAsync(ProjectStatusEnum.DONE, projectId);
  }

  playAnimation(id: string) {
    const animationItem = this.animationItems.get(id);
    if (!animationItem) return;

    this.lottiePlayer.playAnimation(animationItem);
  }

  pauseAnimation(id: string) {
    const animationItem = this.animationItems.get(id);
    if (!animationItem) return;

    this.lottiePlayer.goToAndPause(animationItem, 1);
  }

  public animationCreated(anmimationItem: AnimationItem, id: string) {
    this.animationItems.set(id, anmimationItem);
  }
}
