import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProject } from 'src/app/models/project-model';
import { ProjectAuthApiService } from '../../api/auth/project-auth-api.service';
import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectConverterService } from '../../project/convertors/project-converter.service';
import { IScene } from 'src/app/models/project/scene-model';
import { ITake } from 'src/app/models/project/take/take-model';
import {
  ProjectNotFoundError,
  ProjectNotMatchError,
} from 'src/app/models/errors/project-errors/project-errors';
import { SceneNotFoundError } from 'src/app/models/errors/project-errors/scenes-errors';
import { IExportEditJob } from 'src/app/models/project/edit/edit-model';
import { SharedProjectDBService } from './shared-project-indexdb.service';
import { ProjectMainEventsService } from '../../socket/project/project-main-events.service';
import { ArtDirectorService } from '../../art-director.service';

@Injectable()
export class ProjectStoreService {
  private _projectChangedSubject = new BehaviorSubject<IProject>(null);
  public projectChanged$ = this._projectChangedSubject.asObservable();

  /// Current working project
  private _projectSourceSubject = new BehaviorSubject<IProject>(null);
  public projectSource$ = this._projectSourceSubject.asObservable();

  /// In this event, we know that we have the same project in the project store
  /// useful when the user returns to the same project over and returns
  private _projectWasNotChangedSubject = new BehaviorSubject<void>(null);
  public projectWasNotChanged$ =
    this._projectWasNotChangedSubject.asObservable();

  constructor(
    private projectApiService: ProjectAuthApiService,
    private projectConverter: ProjectConverterService,
    private projectSharedIndexDB: SharedProjectDBService,
    private projectSocketMainService: ProjectMainEventsService,
    private artDirector: ArtDirectorService
  ) {}

  public async setProjectSourceAfterRequestingFromApiAsync(projectId: string) {
    const currentProject = this._projectSourceSubject.value;
    if (!currentProject || currentProject.id !== projectId) {
      return;
    }
    const project = await this.getProjectAsync(currentProject.id, false, null);
    this.setProjectSource(project);
  }

  /**
   * Updates multiple properties of the project in the project source.
   *
   * @param {string} projectId - The ID of the project to update.
   * @param {Object} properties - An object containing key-value pairs of properties to update.
   */
  public updateProjectProperties(
    projectId: string,
    properties: { [key: string]: any }
  ): void {
    if (!projectId || !properties || Object.keys(properties).length === 0) {
      throw new MissingArgumentsError(
        `Could not update project properties because one or more arguments are invalid or empty`
      );
    }

    const projectSource = this._projectSourceSubject.value;
    if (!projectSource) {
      console.error(`No project source is initialized for updates.`);
      throw new ProjectNotFoundError(
        `No project source is initialized for updates.`
      );
    }

    if (projectSource.id !== projectId) {
      console.error(`Project ID does not match the current project source.`);
      throw new ProjectNotMatchError(
        `Project ID does not match the current project source.`
      );
    }

    Object.keys(properties).forEach((key) => {
      if (projectSource.hasOwnProperty(key)) {
        projectSource[key] = properties[key];
      } else {
        console.error(`Property key '${key}' is not valid for this project.`);
        throw new Error(`Property key '${key}' is not valid for this project.`);
      }
    });
    this.setProjectSource(projectSource);
  }

  public get getProject(): IProject {
    return this._projectSourceSubject.value;
  }

  public async setProjectSourceIfNotExistedAsync(
    projectId: string,
    waitForLocalVideos: boolean,
    streamId: string
  ) {
    if (!projectId) {
      throw new MissingArgumentsError(
        `Could not get project source because one of thee arguments is null`
      );
    }

    const currentProject = this._projectSourceSubject.value;

    const currentProjectId = currentProject?.id;
    /// Checking eithher if we don't have current project source,
    /// and if the current project source is existed so if it equals to the project id we want to setProjectSourceAfterRequestingFromApi
    if (!currentProjectId || currentProjectId !== projectId) {
      await this.setCurrentProjectSourceAsync(
        projectId,
        waitForLocalVideos,
        streamId
      );
    } else {
      console.log(
        `No need to set project. Current project source with id ${projectId} already set`
      );
      this._projectWasNotChangedSubject.next();
    }
    return this._projectSourceSubject.value;
  }

  public async setCurrentProjectSourceAsync(
    projectId: string,
    waitForLocalVideos: boolean,
    streamId?: string
  ) {
    if (!projectId) {
      throw new MissingArgumentsError(
        `Could not set current project source because one of thee arguments is null`
      );
    }

    const currentProject = this._projectSourceSubject.value;
    if (currentProject) {
      this.projectSocketMainService.disconnectFromRoom(currentProject.id);
    }
    // const startOfDBName = this.projectConverter..getStartOfDBName();
    /// Awaiting to fetch the current databases theat are not of the current project so we will be safe with the current project ;)
    try {
      const removeDBPromise =
        this.projectSharedIndexDB.removeProjectsFromIndexDBAsync(projectId);
    } catch (error) {
      console.error(`Could not remove all projects from indexdb`, error);
    }

    const project = await this.getProjectAsync(
      projectId,
      waitForLocalVideos,
      streamId
    );
    this.projectSocketMainService.joinProjectRoom(projectId);

    this.setProjectSource(project);

    return project;
  }

  private getProjectAsync(
    projectId: string,
    waitForLocalVideos: boolean,
    streamId: string
  ) {
    const projectPromise = new Promise<IProject>((resolve, reject) => {
      this.projectApiService.getProjectById$(projectId).subscribe({
        next: async (inProject) => {
          if (!inProject) {
            console.error(`No project recieved`);
            throw new Error(`Not recieved`);
          }

          const project = await this.projectConverter.inToLocalAsync(
            inProject,
            waitForLocalVideos,
            streamId
          );

          resolve(project);
        },
        error: (error: HttpErrorResponse) => {
          console.error(
            `Could not get project. Error code: ${error.status}, message: ${error.message}`
          );
          throw new Error(`Error occurred`);
        },
      });
    });
    return projectPromise;
  }

  public setProjectSource(project: IProject) {
    if (!project) {
      throw new MissingArgumentsError(
        `Could not set project source because one of the arguments is null`
      );
    }
    const currentProject = this._projectChangedSubject.value;
    const newProjectId = project.id;
    if (currentProject?.id !== newProjectId) {
      this._projectChangedSubject.next(project);
      this.artDirector.clearLoadedAssets();
    }

    this._projectSourceSubject.next(project);
  }

  /**
   * Updates the scenes of a specific project in the project source. This method
   * either replaces existing scenes with the provided scenes or adds new scenes
   * if they do not exist in the project source already, based on scene IDs.
   *
   * The method first checks if the provided `projectId` and `scenes` are not null or undefined.
   * It ensures that the `scenes` is an array, and checks if the `_projectSourceSubject` is initialized.
   * It then checks if the `_projectSourceSubject` ID matches the provided `projectId`. If all conditions
   * are met, it maps over the existing scenes and updates or retains them depending on whether
   * a match is found in the provided scenes. It also adds new scenes to the project source
   * if they are not present.
   *
   * @param {string} projectId - The ID of the project for which the scenes need to be updated.
   * @param {IScene[] | IScene} scenes - An array of scenes or a single scene to update in the project.
   *                                     This will replace or add to the existing scenes based on scene IDs.
   * @throws {MissingArgumentsError} Throws an IllegalArgumentException if either `projectId`
   *         or `scenes` is null or undefined, indicating missing mandatory fields.
   * @throws {Error} Throws an Error if no project source is initialized or if the project IDs do not match,
   *         indicating a state where updates cannot be proceeded with.
   */
  public replaceOrAddProjectScenes(
    projectId: string,
    scenes: IScene[] | IScene,
    index: number
  ) {
    if (!projectId || !scenes) {
      throw new MissingArgumentsError(
        `Could not set scenes for project because one of the arguments is null`
      );
    }

    // Ensure that 'scenes' is an array.
    if (!Array.isArray(scenes)) {
      scenes = [scenes];
    }

    const projectSource = this._projectSourceSubject.value;
    if (!projectSource) {
      console.error(`No project source is initialized to set his scenes.`);
      throw new ProjectNotFoundError(
        `No project source is initialized to set his scenes.`
      );
    }

    if (projectSource.id !== projectId) {
      console.error(`Project id does not match the current project source.`);
      throw new ProjectNotMatchError(
        `Project id does not match the current project source.`
      );
    }

    // Create a map for quick access to new scenes by their IDs.
    const newScenesMap = new Map(scenes.map((scene) => [scene.id, scene]));

    // Update existing scenes or add new scenes if they don't exist in the project source.
    projectSource.scenes = projectSource.scenes.map((scene) =>
      newScenesMap.has(scene.id) ? newScenesMap.get(scene.id) : scene
    );

    // Add any new scenes that don't exist in the current project source's scenes.
    const existingSceneIds = new Set(
      projectSource.scenes.map((scene) => scene.id)
    );
    scenes.forEach((scene) => {
      if (!existingSceneIds.has(scene.id)) {
        projectSource.scenes.splice(index, 0, scene);
      }
    });
    this.setProjectSource(projectSource);
  }

  public replaceOrAddProjectTakes(
    projectId: string,
    sceneId: string,
    takes: ITake | ITake[]
  ) {
    if (!projectId || !sceneId || !takes) {
      throw new MissingArgumentsError(
        `Could not set takes for project because one of the arguments is null`
      );
    }

    // Ensure that 'takes' is an array.
    if (!Array.isArray(takes)) {
      takes = [takes];
    }

    const projectSource = this._projectSourceSubject.value;
    if (!projectSource) {
      console.error(`No project source is initialized to set his takes.`);
      throw new ProjectNotFoundError(
        `No project source is initialized to set his takes.`
      );
    }

    const currentScene = projectSource.scenes.find(
      (scene) => scene.id === sceneId
    );
    if (!currentScene) {
      console.error(`No scene match with id: ${sceneId}`);
      throw new SceneNotFoundError(`No scene match with id: ${sceneId}`);
    }

    // Create a map for quick access to new takes by their IDs.
    const newTakesMap = new Map(takes.map((take) => [take.id, take]));

    // Update existing takes or add new takes if they don't exist in the project source.
    currentScene.takes = currentScene.takes.map((take) =>
      newTakesMap.has(take.id) ? newTakesMap.get(take.id) : take
    );
    // Add any new takes that don't exist in the current project source's takes.
    const existingTakesIds = new Set(currentScene.takes.map((take) => take.id));

    takes.forEach((take) => {
      if (!existingTakesIds.has(take.id)) {
        currentScene.takes.push(take);
      }
    });

    // Update the chosenTake if it exists in newTakesMap
    if (
      currentScene.chosenTake &&
      newTakesMap.has(currentScene.chosenTake.id)
    ) {
      currentScene.chosenTake = newTakesMap.get(currentScene.chosenTake.id);
    }
    this.setProjectSource(projectSource);
  }

  /**
   * Updates a specific property of a scene in the project source.
   *
   * @param {string} projectId - The ID of the project containing the scene.
   * @param {string} sceneId - The ID of the scene to update.
   * @param {string} propertyKey - The key of the property to update.
   * @param {any} newValue - The new value to setProjectSourceAfterRequestingFromApi for the property.
   */
  public updateSceneProperty(
    projectId: string,
    sceneId: string,
    propertyKey: string,
    newValue: any
  ): void {
    if (!projectId || !sceneId || !propertyKey) {
      throw new MissingArgumentsError(
        `Could not update scene property because one or more arguments are null`
      );
    }

    const project = this._projectSourceSubject.value;
    if (!project || project.id !== projectId) {
      console.error(`Project not found or project ID does not match.`);
      throw new ProjectNotFoundError(
        `Project not found or project ID does not match.`
      );
    }

    const scene = project.scenes.find((scene) => scene.id === sceneId);
    if (!scene) {
      console.error(`Scene not found with ID: ${sceneId}`);
      throw new SceneNotFoundError(`Scene not found with ID: ${sceneId}`);
    }

    // Use bracket notation to dynamically setProjectSourceAfterRequestingFromApi the property
    if (scene.hasOwnProperty(propertyKey)) {
      scene[propertyKey] = newValue;
      this.setProjectSource(project);
      console.log(`Updated scene property ${propertyKey} to ${newValue}`);
    } else {
      console.error(
        `Property key '${propertyKey}' is not valid for this scene.`
      );
      throw new Error(
        `Property key '${propertyKey}' is not valid for this scene.`
      );
    }
  }

  public replaceOrAddProjectEdits(
    projectId: string,
    edits: IExportEditJob | IExportEditJob[]
  ) {
    if (!projectId || !edits) {
      throw new MissingArgumentsError(
        `Could not set edits for project because one of the arguments is null`
      );
    }

    // Ensure that 'edits' is an array.
    if (!Array.isArray(edits)) {
      edits = [edits];
    }

    const projectSource = this._projectSourceSubject.value;
    if (!projectSource) {
      console.error(`No project source is initialized to set his edits.`);
      throw new ProjectNotFoundError(
        `No project source is initialized to set his edits.`
      );
    }

    // Create a map for quick access to new edits by their IDs.
    const newEditsMap = new Map(edits.map((edit) => [edit.id, edit]));

    // Update existing edits or add new edits if they don't exist in the project source.
    projectSource.edits = projectSource.edits.map((edit) =>
      newEditsMap.has(edit.id) ? newEditsMap.get(edit.id) : edit
    );

    // Add any new edits that don't exist in the current project source's edits.
    const existingEditsIds = new Set(
      projectSource.edits.map((edit) => edit.id)
    );

    edits.forEach((edit) => {
      if (!existingEditsIds.has(edit.id)) {
        projectSource.edits.push(edit);
      }
    });
    this.setProjectSource(projectSource);
  }
}
