<!-- ai-text.component.html -->
<div
  class="ai-scroller"
>
  <span
    *ngFor="let text of textAi"
    class="text-span"
    (click)="voiceRecognition.jumpToWord(text)"
    [matTooltip]="'Click to open menu'"
    [class.word]="text.type === 'word'"
    [class.symbol]="text.type === 'symbol'"
    [class.space]="text.type === 'space'"
    [class.done]="voiceRecognition.lastFoundWord.value && text.index <= voiceRecognition.lastFoundWord.value.index"
    [class.period]="text?.type === 'symbol' && text.text?.trim()?.endsWith('.')"
    [id]="text.index"
    matBadge="1"
    matBadgeOverlap="false"
    matBadgePosition="after"
    [matBadgeHidden]="text.type !== 'word' || !text.events || text.events.length === 0"
    matBadgeColor="primary"
  >{{ text.text }}</span>
</div>
