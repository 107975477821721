import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import {
  BehaviorSubject,
  combineLatest,
  first,
  map,
  Observable,
  Subscription,
} from 'rxjs';
import { IFakeDetection, IPathAndDash } from 'src/app/models/defines';
import {
  IBasicLottieLayer,
  IDynamicLottieData,
} from 'src/app/models/lottie/lottie-defines';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LottiePlayerService } from 'src/app/services/lottie-player.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import {
  IComposedEditTakeConfigs,
  ISubtitleSettings,
  IVideoLottieCompose,
} from 'src/app/models/job/editor-defines';
import { IVideoLayer } from 'src/app/models/project/take/layers/video-model';
import { UserService } from 'src/app/services/api/auth/user.service';
import { JassubService } from '../../services/jassub.service';
import { EditRoomStateService } from 'src/app/pages/private/dashboard/project/edit-room/edit-room-state.service';
import { v4 as uuidv4 } from 'uuid';
import { SubtitlesHelper } from 'subtitles-helper/dist/subtitles-helper';
import { IFont } from '../../models/configs/fonts.model';
import { FontsStoreService } from '../../services/state-management/configs/fonts-store.service';
import {
  bounceOutOnLeaveAnimation,
  bounceInOnEnterAnimation,
  flipInXOnEnterAnimation,
} from 'angular-animations';

interface IVgAndCanBePlayed {
  vgApi: VgApiService;
  canBePlayed: boolean;
  videoLayer: IVideoLayer;
}

interface ILottieLayerAndOptions {
  lottieLayer: IBasicLottieLayer;
  lottieData: IDynamicLottieData;
  options: AnimationOptions;
  isHidden$: BehaviorSubject<boolean>;
  startInFrame: number;
  endInFrame: number;
  totalFrames: number;
}

interface IComposedTake {
  id: string;
  lottiesLayersAndOptions: ILottieLayerAndOptions[];
  videoLayers: IVideoLayer[];
  duration: number;
}

interface IAnimationAndSubscription {
  animationItem: AnimationItem;
  subscriptionToChanges: Subscription;
}

@Component({
  selector: 'app-lottie-video-composed',
  templateUrl: './lottie-video-composed.component.html',
  styleUrls: ['./lottie-video-composed.component.scss'],
  animations: [
    bounceInOnEnterAnimation(),
    bounceOutOnLeaveAnimation(),
    flipInXOnEnterAnimation(),
  ],
})
export class LottieVideoComposedComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit
{
  resizeTimeout: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.updateSubtitleColorsAndStyles();
    }, 300); // Adjust the debounce delay as needed
  }

  @ViewChild('subtitlesCanvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  subjectsToUnsubsribe: Subscription[] = [];
  @Input()
  displayVgControls: boolean = true;
  @Input()
  flipHorizontally: boolean = false;

  @Input()
  playOrPause: IFakeDetection;
  playOrPause$ = new BehaviorSubject<IFakeDetection>(null);

  /// Being rendered but not played .
  @Input()
  playNowOrWait: boolean = false;
  playNowOrWait$ = new BehaviorSubject<boolean>(false);

  /// To know if to freeze when play now or wait is being false, meaning we want to wait until palying but to see first frame
  @Input()
  freezeAtFirstFrame: boolean = true;

  @Output()
  freezedAtFirstFrame = new EventEmitter<void>();

  @Input()
  notifyTime: boolean = true;

  @Input()
  showLoadingIndication: boolean = false;

  @Input()
  showPlayPauseOverlay: boolean = false;

  /// To get the interval time to notify on what time im on
  @Input()
  intervalNotifierTime: number = 0;
  notifyIntervalId: any;
  timeUpdateSubscription: Subscription;

  /// To notify the parent which time im on
  @Output()
  currentVideoTime = new EventEmitter<number>();

  @Output()
  currentLottieTime = new EventEmitter<number>();

  /// Doing an array because a edit take might have few lotties,
  /// In the future, each lottie will have time to start in the edit take property,
  /// so we will know when to make them visible in the dom
  /// Right now they are visible from the start (right now we only have 1 lottie per edit take)
  @Input('compose-configs')
  composeConfigs: IComposedEditTakeConfigs;
  composeConfigs$ = new BehaviorSubject<IComposedEditTakeConfigs>(null);
  composesSubscriptions: Subscription;

  /**
   * special times - starting from the middle of edit take
   */
  @Input('start-time-on-running-edit-take')
  newStartTime: number;

  /**
   * special times - starting from the middle of Take, cutting a Take etc ..
   */
  @Input('special-end-time')
  endTime: number;

  @Output('is-loading')
  isLoading = new BehaviorSubject<boolean>(true);

  @Output('started-playing-edit-take')
  startedPlayingEditTake = new EventEmitter<string>();

  @Output('finished-playing-edit-take')
  finishedPlayingEditTake = new EventEmitter<string>();
  @Output() videoReady = new EventEmitter<string>();

  /**
   *  Request external playlist or tracks to play/pause all
   *  This is useful when the user the player is part of a sequence of videos
   */
  @Output() pauseRequested = new EventEmitter<void>();
  @Output() playRequested = new EventEmitter<void>();

  framesRate: number;
  fakeVideoUrl = '/files/platform/assets/fake.mp4';
  lottieLayersAndOptions$ = new BehaviorSubject<ILottieLayerAndOptions[]>(null);
  composedEditTakes$ = new BehaviorSubject<IComposedTake[]>(null);
  ///TODO: probably delete later
  composedEditTakes: IComposedTake[] = [];

  startInFrame$ = new BehaviorSubject<number>(null);
  startInFrameSubscription: Subscription;

  endInFrame$ = new BehaviorSubject<number>(null);
  endInFrameSubscription: Subscription;

  baseCdnUrl: string;
  vgApisMap = new Map<string, IVgAndCanBePlayed>();
  areVideosReady$ = new BehaviorSubject<boolean>(false);
  canPlayVideosSubscription: Subscription[] = [];

  /// Currently Playing/Playable Lotties
  lottiePlayersMap = new Map<string, IAnimationAndSubscription>();
  /// the key is the lottie id, the value is the composed edit take id + lottie id (updated by the required Lotties that needs to be played)
  requiredUniqueLottiesIdMap = new Map<string, string>();

  areLottiesReady$ = new BehaviorSubject<boolean>(false);
  didLottieCleanup$ = new BehaviorSubject<boolean>(true);
  isFirst: boolean = true;
  viewHaveBeenInit$ = new BehaviorSubject<boolean>(false);

  /// if there are lotties in the player and they start before frame by the waitinf rule, we wait for the animations to start play and then playing video
  /// It's done so the video won't show before the lottie does :)
  lottieStartedPromises: Promise<void>[] = [];
  ruleFramesToWaitForLottie = 2;

  isHidden = true;

  /// When dragging from scrub bar we want to freeze the animation and not play it while dragging .
  draggingMode: boolean = false;

  /// if the user stopped the player and dragged, the video should stay paused, if the video was running, after drag it should keep running
  shouldRunAfterDragging: boolean;
  totalTime: number = 0;

  private videoEndedSubscription: Subscription;
  private currentTime: number = 0;
  isIOS: boolean = false;
  fonts: IFont[] = [];
  toHideVideoLayer = false;

  constructor(
    private lottiePlayer: LottiePlayerService,
    private config: ConfigurationService,
    private jassubService: JassubService,
    private ngZone: NgZone,
    private userService: UserService,
    public editRoomStateService: EditRoomStateService,
    private fontsStoreService: FontsStoreService
  ) {
    this.framesRate = 30;
    this.baseCdnUrl = this.config.baseCdnUrl;
    this.isIOS = this.userService.isIOS;
  }

  ngAfterViewInit(): void {
    this.viewHaveBeenInit$.next(true);
  }

  ngOnInit(): void {
    this.editRoomStateService.subtitleChanged$.subscribe(() => {
      this.updateSubtitleColorsAndStyles();
      if (this.currentTime) {
        this.emitNewTime(this.currentTime + 0.001);
      }
    });
    this.subjectsToUnsubsribe.push(
      this.playOrPause$.subscribe((playOrPause) => {
        if (!playOrPause || !this.playNowOrWait) {
          return;
        }
        this.playOrPause = playOrPause;

        if (playOrPause.isPlaying) {
          if (!this.composeConfigs$.value) {
            return;
          }
          new Promise<void>((resolve, reject) => {
            for (const lottieAndSub of this.lottiePlayersMap.values()) {
              const animationItem = lottieAndSub.animationItem;
              const currentTime = this.lottiePlayer.getCurrentPlayingFrame();
              this.lottiePlayer.playOrFreezeAnimationBetweenFrames(
                animationItem,
                currentTime,
                this.endInFrame$.value,
                true
              );
            }
            return resolve();
          });

          new Promise<void>((resolve, rejecet) => {
            for (const [
              id,
              vgPlayerAndCanBePlayed,
            ] of this.vgApisMap.entries()) {
              vgPlayerAndCanBePlayed.vgApi?.getDefaultMedia()?.play();
            }
            return resolve();
          });
          for (const vgPlayerAndCanBePlayed of this.vgApisMap.values()) {
            const currentTime =
              vgPlayerAndCanBePlayed.vgApi.getDefaultMedia().currentTime;
          }
        }
        /// Pausing
        else {
          new Promise<void>((resolve, reject) => {
            for (const lottieAndSub of this.lottiePlayersMap.values()) {
              this.lottiePlayer.pauseAnimation(lottieAndSub.animationItem);
            }
            return resolve();
          });
          new Promise<void>((resolve, reject) => {
            for (const vgPlayerAndCanBePlayed of this.vgApisMap.values()) {
              vgPlayerAndCanBePlayed.vgApi.getDefaultMedia().pause();
            }
            return resolve();
          });
        }
      })
    );

    this.resetRelevantSubjects();
    this.subjectsToUnsubsribe.push(
      this.composedEditTakes$.subscribe((composedTakes) => {
        this.composedEditTakes = composedTakes;
      })
    );

    this.subjectsToUnsubsribe.push(
      this.composeConfigs$.subscribe(async (editTakeConfigs) => {
        clearInterval(this.notifyIntervalId);
        this.timeUpdateSubscription?.unsubscribe();

        this.vgApisMap.clear();
        this.lottieStartedPromises = [];
        this.isHidden = true;
        // this.requiredUniqueLottiesIdMap.clear();
        if (!editTakeConfigs) {
          this.composedEditTakes$.next(null);
          return;
        }
        try {
          this.fonts = await this.fontsStoreService.getFontsAsync();
        } catch (error) {
          this.fonts = [];
        }
        this.resetRelevantSubjects();
        const builtEditTakes = editTakeConfigs.videoLottieConfigs.map(
          (composeConfig) => {
            if (
              !composeConfig.videoLayers ||
              composeConfig.videoLayers.length === 0
            ) {
              const fakeId = uuidv4();
              composeConfig.videoLayers = [
                {
                  id: fakeId,
                  uploadPath: this.fakeVideoUrl,
                  width: 100,
                  height: 100,
                  storeManagerId: uuidv4,
                  trimEnd: 0,
                  trimStart: 0,
                  uinuqeId: uuidv4,
                  videoPosition: {
                    id: fakeId,
                    position: 100,
                    width: 100,
                    height: 100,
                    y: 0,
                    x: 0,
                  },
                },
              ];
            }
            return this.buildComposedTake(
              composeConfig,
              editTakeConfigs.endTime - editTakeConfigs.startTime
            );
          }
        );

        const allLottiesAreSet = this.checkIfLottiesAreSyncdWhenNewConfigs();
        this.areLottiesReady$.next(allLottiesAreSet);
        if (this.isFirst) {
          this.didLottieCleanup$.next(true);
          this.isFirst = false;
        } else {
          this.didLottieCleanup$.next(allLottiesAreSet);
        }
        const validatedStartTime = this.validateTime(
          editTakeConfigs.startTime,
          0
        );
        const validatedEndTime = this.validateTime(editTakeConfigs.endTime, 1);
        if (validatedStartTime && validatedEndTime) {
          /// Reset the current times so it won't trigger events because if end time exists and i trigger
          /// another start time, it will say true on conditions .
          this.startInFrame$.next(null);
          this.endInFrame$.next(null);

          this.startInFrame$.next(
            this.convertTimeToFrames(editTakeConfigs.startTime, 0)
          );

          this.endInFrame$.next(
            this.convertTimeToFrames(editTakeConfigs.endTime, 1)
          );
        }
        this.composedEditTakes$.next(builtEditTakes);
        /// Checking if there are no video layers in our compositions that are currently playing
        if (
          builtEditTakes.filter((editTake) => editTake.videoLayers.length !== 0)
            .length === 0
        ) {
          this.areVideosReady$.next(true);
        }
      })
    );

    const combinedSubjects$ = combineLatest([
      this.composedEditTakes$,
      this.startInFrame$,
      this.endInFrame$,
      this.areVideosReady$,
      this.areLottiesReady$,
      this.playNowOrWait$,
    ]);

    /// Not using here this.composedTakes because it might be triggered before initialize them to the current value .
    this.composesSubscriptions = combinedSubjects$
      .pipe(
        map(
          async ([
            composedTakes,
            startInFrame,
            endInFrame,
            videosReady,
            lottiesReady,
            playNowOrWait,
          ]) => {
            if (
              !playNowOrWait ||
              !composedTakes ||
              startInFrame === null ||
              endInFrame === null ||
              !videosReady ||
              !lottiesReady
            ) {
              this.lottiePlayersMap.forEach((animationItem) => {
                animationItem.animationItem.stop();
              });
              // this.vgApisMap.forEach((vgApi) => {
              //   vgApi.pause();
              // });
              return;
            }
            this.lottiePlayer.resetCurrentLoopCounter();

            const activeLottiesPromise = new Promise<void>(
              async (lottieResolve, lottieReject) => {
                composedTakes.map((composedTake) => {
                  composedTake.lottiesLayersAndOptions?.map(
                    async (lottieLayerAndOptions) => {
                      const framesUntilPlay =
                        lottieLayerAndOptions.startInFrame - startInFrame;

                      if (framesUntilPlay > 0) {
                        /// Set frame time out to start display the lottie ! will change it when relevant
                        lottieLayerAndOptions.isHidden$.next(true);
                        const millisecondsUntilPlay =
                          (framesUntilPlay / this.framesRate) * 1000;
                        ///TODO: fix this because on pausing its not good (lotties that are inserted in the middle of the take)
                        await new Promise((resolve) =>
                          setTimeout(resolve, millisecondsUntilPlay)
                        );
                      }
                      /// 'Activating'(show and plays the loaded lotties) by unhide them (triggers subject to play in the animationCreated)
                      lottieLayerAndOptions.isHidden$.next(false);
                    }
                  );
                });
                return lottieResolve();
              }
            );
            /// Waiting for all basic lotties to be played and then we play the mp4 so it won't look emberssing
            await activeLottiesPromise;
            const activeVideosPromise = new Promise<void>(
              async (videoResolve, videoReject) => {
                if (this.lottieStartedPromises.length > 0) {
                  await Promise.all(this.lottieStartedPromises);
                }
                if (this.vgApisMap.size === 0) {
                  return videoResolve();
                }

                for (const [id, vgApiAndCanBePlayed] of this.vgApisMap) {
                  const defaultMedia =
                    vgApiAndCanBePlayed.vgApi.getDefaultMedia();
                  vgApiAndCanBePlayed.vgApi.seekTime(
                    this.convertFrameToSeconds(startInFrame) +
                      vgApiAndCanBePlayed.videoLayer.trimStart / 1000
                  );

                  if (vgApiAndCanBePlayed.vgApi.canPlayThrough) {
                    //console.log(`ICAN PLAY THROUGH`);
                    if (this.playOrPause.isPlaying) {
                      defaultMedia?.play();
                    }
                  }
                  this.subjectsToUnsubsribe.push(
                    defaultMedia.subscriptions.waiting.subscribe((error) => {
                      //    console.warn('WAITING ', error);
                    })
                  );
                  this.subjectsToUnsubsribe.push(
                    defaultMedia.subscriptions.canPlay.subscribe((error) => {
                      //  console.warn('WAITING PLAYING AGAIN ', error);
                      if (this.playOrPause.isPlaying) {
                        vgApiAndCanBePlayed.vgApi?.getDefaultMedia()?.play();
                      }
                    })
                  );
                }

                /// Temp, we take the first end of the first video and trigger the finished take
                for (const [id, vgApiAndCanBePlayed] of this.vgApisMap) {
                  const defaultMedia =
                    vgApiAndCanBePlayed.vgApi.getDefaultMedia();
                  this.subjectsToUnsubsribe.push(
                    /// when i have a specific take, there is a time that the user pause or play,
                    /// there is a need to adjust lottie by that
                    defaultMedia.subscriptions.pause.subscribe((paused) => {
                      console.log(`I PAUSED!`, paused);
                      for (const lottieAndSub of this.lottiePlayersMap.values()) {
                        this.lottiePlayer.pauseAnimation(
                          lottieAndSub.animationItem
                        );
                      }
                    })
                  );

                  /// when i have a specific take, there is a time that the user pause or play,
                  /// there is a need to adjust lottie by that
                  this.subjectsToUnsubsribe.push(
                    defaultMedia.subscriptions.play.subscribe((play) => {
                      for (const lottieAndSub of this.lottiePlayersMap.values()) {
                        if (lottieAndSub.animationItem.isPaused) {
                          this.lottiePlayer.playAnimation(
                            lottieAndSub.animationItem
                          );
                        }
                      }
                    })
                  );

                  break;
                }
                return videoResolve();
              }
            );
            await activeVideosPromise;
            /// Lottie hidden
            this.isHidden = false;
            /// There might times that we don't want to trigger that the take started in the beginning because
            /// The take should be stopped.
            this.startedPlayingEditTake.emit(this.composeConfigs$.value.id);
            this.isLoading.next(false);
            // if (!this.playOrPause || !this.playOrPause.isPlaying) {
            //   // this.playOrPause$.next({
            //   //   randomNumber: Math.random(),
            //   //   isPlaying: false,
            //   // });
            // }
            this.emitTimeOfTake();
          }
        )
      )
      .subscribe();
  }

  handleVideoEnded() {
    clearInterval(this.notifyIntervalId);
    this.videoEndedSubscription?.unsubscribe();
    this.videoEndedSubscription?.unsubscribe();
    console.log('Video has ended!');
    this.finishedPlayingEditTake.emit(this.composeConfigs$.value.id);
  }

  ngOnDestroy(): void {
    this.composesSubscriptions?.unsubscribe();
    this.startInFrameSubscription?.unsubscribe();
    this.canPlayVideosSubscription?.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.subjectsToUnsubsribe?.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.timeUpdateSubscription?.unsubscribe();
    const uniqueId = this.getJassubUniqueId();
    if (uniqueId) {
      this.jassubService.destroyJassubtInstance(uniqueId);
    }
  }

  pauseOrPlayPressed(playerId) {
    const player = this.vgApisMap.get(playerId);
    const isPlaying = player.vgApi.getDefaultMedia().state === 'playing';
    this.playOrPause$.next({
      isPlaying: !isPlaying,
      randomNumber: Math.random(),
    });
  }

  animationCreated(
    animationItem: AnimationItem,
    layerAndOptions: ILottieLayerAndOptions,
    composeTakeId: string,
    lottieId: string
  ) {
    /// if there are lotties in the player and they start before frame 2, we wait for the animations to start play and then playing video
    if (lottieId === '1' || lottieId === '2') {
      return;
    }
    this.subjectsToUnsubsribe.push(
      this.startInFrame$.pipe(first()).subscribe((startInFrame) => {
        if (isNaN(startInFrame)) {
          return;
        }

        /// will be lower or equal to 0 if the start in frame is 0 probably, will be higher if start in frame is bigger and the lottie should be already on screen
        if (layerAndOptions.startInFrame <= this.startInFrame$.value) {
          const lottiePlaying = new Promise<void>((resolve, reject) => {
            animationItem.addEventListener('enterFrame', (event) => {
              // this.editRoomStateService.currentTime$.next(
              //   this.editRoomStateService.currentTime$.value +
              //     event.totalTime
              // );

              // this.currentLottieTime.emit(event.totalTime);
              if (event.currentTime - this.ruleFramesToWaitForLottie > 0) {
                this.ngZone.run(() => {
                  resolve();
                });
                return animationItem.removeEventListener('enterFrame');
              }
            });
          });
          this.lottieStartedPromises.push(lottiePlaying);
        }
      })
    );

    console.log(`DOM LOADED ??  ${animationItem.isLoaded}`);
    // animationItem.addEventListener('DOMLoaded', () => {
    //   console.log(`DOM LOADED ! ${animationItem.isLoaded}`);
    // });
    console.log('new animation item', animationItem);
    const lottieUniqueId = this.getLottieUniqueId(composeTakeId, lottieId);
    const animationAndSubscription: IAnimationAndSubscription = {
      animationItem: animationItem,
      subscriptionToChanges: null,
    };

    this.lottiePlayersMap.set(lottieUniqueId, animationAndSubscription);

    const lottiesCanBePlayed = this.checkIfLottiesAreSyncdAfterDelete();
    this.areLottiesReady$.next(lottiesCanBePlayed);

    const combinedSubjects$ = combineLatest([
      this.startInFrame$,
      this.endInFrame$,
      layerAndOptions.isHidden$,
      this.didLottieCleanup$,
      this.viewHaveBeenInit$,
    ]);

    if (animationItem.isLoaded) {
      this.handleLottieDomLoaded(
        animationAndSubscription,
        combinedSubjects$,
        lottieUniqueId
      );
    } else {
      animationItem.addEventListener('DOMLoaded', () => {
        this.handleLottieDomLoaded(
          animationAndSubscription,
          combinedSubjects$,
          lottieUniqueId
        );
      });
    }
  }

  requestPlayOrPause() {
    this.playOrPause.isPlaying
      ? this.pauseRequested.emit()
      : this.playRequested.emit();
  }

  onPlayerReady(api: VgApiService, videoId: string, videoLayer: IVideoLayer) {
    if (!api) {
      return;
    }
    this.areVideosReady$.next(false);
    const vgAndCanBePlayed: IVgAndCanBePlayed = {
      vgApi: api,
      canBePlayed: false,
      videoLayer: videoLayer,
    };

    this.vgApisMap.set(videoId, vgAndCanBePlayed);
    const defaultMedia = api.getDefaultMedia();
    if (!defaultMedia) {
      return;
    }
    // Subscribe to the 'canPlayThrough' event to know when the media is ready to be played
    const canPlayThrough$ = defaultMedia.subscriptions.canPlayThrough;
    const loadedMetadata$ = defaultMedia.subscriptions.loadedMetadata;

    // To be able to load the subtitles, we need to wait for the metadata to be loaded
    loadedMetadata$.subscribe(() => {
      const composeConfigs = this.composeConfigs$.value;
      if (!composeConfigs) {
        return;
      }
      /// Console log each necessary value for debugging
      console.log('this.canvas?.nativeElement', this.canvas?.nativeElement);
      console.log('defaultMedia.elem', defaultMedia.elem);

      this.initalizeSubtitles(api);
      try {
      } catch (jassubError) {
        console.error(`Could not display subtitles`, jassubError);
      }

      // We will currently use default media because there's one video, but later we can use the id of the video element
      // defaultMedia.elem

      // this.loadSubtitles(videoId, vgAndCanBePlayed);
    });

    // Check if the video is ready to be played - both canPlayThrough and loadedMetadata should be true
    const combinedSubjects$ = combineLatest([canPlayThrough$, loadedMetadata$]);
    this.canPlayVideosSubscription.push(
      combinedSubjects$
        .pipe(
          map(([canPlayThrough, loadedMetadata]) => {
            if (!canPlayThrough || !loadedMetadata) {
              return;
            }
            // Update the canBePlayed flag to true
            vgAndCanBePlayed.canBePlayed = true;
            this.videoReady.emit(videoId);

            // Check if all videos are ready to be played
            this.checkAllVideosAreReady();
          })
        )
        .subscribe()
    );

    console.log(`[onPlayerReady()]: api recieved for id ${videoId}`);
  }

  private createAssText(
    subtitlesConfigs: ISubtitleSettings,
    videoName: string
  ) {
    // Filter out sentences where isHidden is true
    const filteredSentences =
      subtitlesConfigs.take.copy.transcript.sentences.filter(
        (sentence) => !sentence.isHidden
      );
    // const sentences = subtitlesConfigs.sentences;
    const strategyToPlay = subtitlesConfigs.configs.strategy;
    const styles = subtitlesConfigs.styles;
    const canvasWidth = this.canvas.nativeElement.clientWidth;
    const canvasHeight = this.canvas.nativeElement.clientHeight;

    console.log(
      `COMPOSE`,
      this.composeConfigs,
      'TAKE COPY',
      subtitlesConfigs.take.copy.transcript.sentences
    );
    const assObj = SubtitlesHelper.transcriptToAssJSON(
      filteredSentences,
      strategyToPlay,
      styles,
      1920,
      1080,
      videoName ?? ''
    );

    const assText = SubtitlesHelper.stringify(assObj);
    return assText;
  }

  //** End of Vg player events */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['playNowOrWait']) {
      this.playNowOrWait$.next(this.playNowOrWait);
    }

    if (changes['composeConfigs']) {
      this.composeConfigs$.next(changes['composeConfigs'].currentValue);
    }
    /// Setting new options when input changes.
    if (changes['playOrPause']) {
      this.playOrPause$.next(this.playOrPause);
    }

    if (changes['newStartTime']) {
      const validateStartTime = this.validateTime(this.newStartTime, 0);
      if (!validateStartTime) {
        console.warn(`Could not play new start time because he is not valid`);
        return;
      }
      this.emitNewTime(this.newStartTime);
      // this.startInFrame$.next(changes['newStartTime'].currentValue);

      // this.startInFrame = validateStartTime / this.framesRate;
      //this.startInFrame$.next(this.convertTimeToFrames(this.startTime, 0));
    }
    if (changes['endTime']) {
      const validateEndTime = this.validateTime(this.endTime, 1);
      this.endInFrame$.next(changes['endTime'].currentValue);
      // this.endInFrame = validateEndTime / this.framesRate;
      // this.endInFrame$.next(this.convertTimeToFrames(this.endTime, 1));
    }

    if (changes['videosSourcesOnTrack']) {
      clearInterval(this.notifyIntervalId);
      this.timeUpdateSubscription?.unsubscribe();
      this.vgApisMap.clear();
    }
  }

  private emitNewTime(newStartTime: number) {
    this.startInFrame$.next(this.convertTimeToFrames(newStartTime, 0));
    this.playOrPause$.next(this.playOrPause);
  }

  dragStartedFromScrubBar() {
    this.shouldRunAfterDragging = this.playOrPause.isPlaying;
    if (this.playOrPause) {
      this.playOrPause$.next({ isPlaying: false, randomNumber: Math.random() });
    }

    this.draggingMode = true;
  }

  dragEndedFromScrubBar() {
    if (this.shouldRunAfterDragging) {
      this.playOrPause$.next({ isPlaying: true, randomNumber: Math.random() });
    }
    this.draggingMode = false;
  }

  newTimeFromScrubBar(timeInMillies: number) {
    const timeInFrame = (timeInMillies * this.framesRate) / 1000;
    this.startInFrame$.next(timeInFrame);
  }

  animationDestroyed(id: string) {
    this.didLottieCleanup$.next(false);
    const animationAndSubscription = this.lottiePlayersMap.get(id);
    animationAndSubscription?.subscriptionToChanges?.unsubscribe();
    ///This happens after the combineLatest inside animationCreated is being triggered again, so we need to wait for deleting first.
    this.lottiePlayersMap.delete(id);
    this.deleteItemFromUniqueLottiesIdMap(id);

    const finishedCleanup = this.checkIfLottiesAreSyncdAfterDelete();
    this.didLottieCleanup$.next(finishedCleanup);
    console.log('LOTTIE REMOVED!', id);
  }

  getLottieUniqueId(composeTakeId: string, lottieId: string) {
    return composeTakeId + lottieId;
  }

  convertFrameToSeconds(frameNumber: number) {
    if (isNaN(frameNumber)) {
      return null;
    }
    return frameNumber / this.framesRate;
  }

  private handleLottieDomLoaded(
    animationAndSubscription: IAnimationAndSubscription,
    combinedSubjects$: Observable<[number, number, boolean, boolean, boolean]>,
    lottieUniqueId: string
  ) {
    animationAndSubscription.subscriptionToChanges = combinedSubjects$
      .pipe(
        map(([startFrame, endFrame, isHidden, didLottieCleanup, viewInit]) => {
          if (!viewInit) {
            return;
          }
          let localAnimationItem: AnimationItem;
          /// Might be  triggered on an animation item that we want to destroy but it was triggered before destroyed
          if (!didLottieCleanup) {
            console.warn('Did not cleanup lotties yet .');
            return { startFrame, endFrame, isHidden, didLottieCleanup };
          }
          /// Might be triggered after being destryoed.
          localAnimationItem =
            this.lottiePlayersMap.get(lottieUniqueId)?.animationItem;

          if (!localAnimationItem) {
            console.error(`AnimationItem with id ${lottieUniqueId} not found.`);
            return { startFrame, endFrame, isHidden, didLottieCleanup };
          }
          if (startFrame === null || endFrame === null || isHidden) {
            return { startFrame, endFrame, isHidden, didLottieCleanup };
          }
          //if (!this.playOrPause.isPlaying) return;
          /// If another frame was clicked on the same animation, we need to stop the current animation and his event listeneres. .
          this.clearEventListeners(localAnimationItem);

          if (!this.freezeAtFirstFrame) {
            this.activateLottieAnimation(
              localAnimationItem,
              startFrame,
              endFrame,
              !this.draggingMode && this.playOrPause.isPlaying
            );
          } else {
            /// Playing lottie with freezing (maybe waiting for something else to play it, depence on the fater component what he wants to do)
            this.activateLottieAnimation(
              localAnimationItem,
              startFrame ?? 3,
              endFrame ?? 4,
              false
            );
            this.freezedAtFirstFrame.emit();
          }
          // distinctUntilChanged();
          return { startFrame, endFrame, isHidden, didLottieCleanup };
        })
      )
      .subscribe();
  }

  private deleteItemFromUniqueLottiesIdMap(specificKey: string): void {
    for (const [key, value] of this.requiredUniqueLottiesIdMap) {
      if (value === specificKey) {
        this.requiredUniqueLottiesIdMap.delete(key);
        return;
      }
    }
  }

  /**
   * When getting new configs, the lotties might be the same so we won't destroy any and the lotties are already ready .
   * @returns true if they do, false if not.
   */
  private checkIfLottiesAreSyncdWhenNewConfigs(): boolean {
    const requiredLottiesEntries = Array.from(
      this.requiredUniqueLottiesIdMap.keys()
    );
    for (const uniqueLottieId of requiredLottiesEntries) {
      // Check if the value exists as a key in the second map
      if (!this.lottiePlayersMap.has(uniqueLottieId)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Checks whether the currently playing/playable Lotties match the updated ones that need to be played,
   * allowing mismatches up to the difference in length between the maps.
   * @returns true if they do, false if there are more mismatches.
   */
  private checkIfLottiesAreSyncdAfterDelete(): boolean {
    const requiredLottiesEntries = Array.from(
      this.requiredUniqueLottiesIdMap.entries()
    );
    const lottiePlayersEntries = Array.from(this.lottiePlayersMap.entries());

    const lengthDifference = Math.abs(
      requiredLottiesEntries.length - lottiePlayersEntries.length
    );

    let mismatches = 0;

    // Iterate through the entries of the first map
    for (const [key, value] of requiredLottiesEntries) {
      // Check if the value exists as a key in the second map
      if (!this.lottiePlayersMap.has(value)) {
        mismatches++;

        // If the number of mismatches is greater than the allowed difference, return false
        if (mismatches > lengthDifference) {
          return false;
        }
      }
    }

    // If the number of mismatches is less than or equal to the allowed difference, return true
    return true;
  }

  private resetRelevantSubjects() {
    this.areVideosReady$.next(false);
    //this.areLottiesReady$.next(false);
    //this.didLottieCleanup$.next(true);
    // this.startInFrame$.next(null);
    // this.endInFrame$.next(null);
  }

  private checkAllVideosAreReady() {
    // Check if every value in the map is ready to play
    const areAllVideosReady = Array.from(this.vgApisMap.values()).every(
      (entry) => entry.canBePlayed
    );

    if (areAllVideosReady) {
      this.areVideosReady$.next(true);
      this.canPlayVideosSubscription.forEach((subscription) =>
        subscription.unsubscribe()
      );
      this.canPlayVideosSubscription = [];
    }
  }

  private buildComposedTake(
    composeConfig: IVideoLottieCompose,
    duration: number
  ) {
    const { basicLottieLayersOnTrack, videoLayers, lottieData } = composeConfig;

    const lottieLayerAndOptions: ILottieLayerAndOptions[] =
      basicLottieLayersOnTrack.map((lottieLayer) => {
        const options = this.createLottieOptions(
          this.baseCdnUrl + lottieLayer.lottieJsonPath
        );
        /// Setting the lotties that are on track unique ids to validate after with the current acivated lotties

        const lottieId = lottieLayer.lottieId;
        const lottieUniqueId = this.getLottieUniqueId(
          composeConfig.id,
          lottieId
        );
        this.requiredUniqueLottiesIdMap.set(lottieId, lottieUniqueId);

        const lottieLayerAndOptions: ILottieLayerAndOptions = {
          lottieLayer: lottieLayer,
          startInFrame:
            (lottieLayer.startTime * this.framesRate + lottieLayer.trimStart) /
            1000,
          endInFrame:
            (lottieLayer.endTime * this.framesRate - lottieLayer.trimEnd) /
            1000,
          totalFrames:
            ((lottieLayer.endTime -
              lottieLayer.startTime -
              lottieLayer.trimStart -
              lottieLayer.trimEnd) *
              this.framesRate) /
            1000,
          options: options,
          lottieData: lottieData,
          isHidden$: new BehaviorSubject<boolean>(true),
        };
        return lottieLayerAndOptions;
      });

    const updatedVideoLayers = videoLayers?.map((videoLayer) => {
      this.toHideVideoLayer = videoLayer.videoPosition.id === 'default';
      let dashPath: string;
      if (videoLayer.dashPath) {
        dashPath = decodeURIComponent(videoLayer.dashPath);
      }
      let uploadPath: string;
      if (videoLayer.uploadPath) {
        uploadPath = decodeURIComponent(videoLayer.uploadPath);
      }
      const pathAndDash: IPathAndDash = {
        uploadPath: uploadPath ?? null,
        dashPath: dashPath ?? null,
      };

      const updatedVideoLayer: IVideoLayer = {
        ...videoLayer,
        ...pathAndDash,
      };
      return updatedVideoLayer;
    });
    const composedTake: IComposedTake = {
      id: composeConfig.id,
      lottiesLayersAndOptions: lottieLayerAndOptions,
      videoLayers: updatedVideoLayers ?? [],
      duration: duration,
    };
    return composedTake;
  }

  private clearEventListeners(animationItem: AnimationItem) {
    animationItem.stop();
    animationItem.removeEventListener('complete');
    animationItem.removeEventListener('loopComplete');
    animationItem.removeEventListener('DOMLoaded');
  }

  /**
   *
   * @param animationItem
   * @param startFrame
   * @param endFrame
   * @param toFreeze - true for playing, false for freezing
   */
  private activateLottieAnimation(
    animationItem: AnimationItem,
    startFrame: number,
    endFrame: number,
    toFreeze: boolean
  ): void {
    // animationItem.addEventListener('DOMLoaded', () => {

    this.lottiePlayer.playOrFreezeAnimationBetweenFrames(
      animationItem,
      startFrame,
      endFrame,
      toFreeze
    );

    //});
  }

  private createLottieOptions(lottiePath: string) {
    const options: AnimationOptions = {
      path: decodeURIComponent(lottiePath),
      autoplay: false,
      loop: false,
      assetsPath: `${this.baseCdnUrl}`,
    };
    return options;
  }

  private convertTimeToFrames(time: number, equalAndAboveTo: number) {
    return !isNaN(time) && time >= equalAndAboveTo
      ? time * this.framesRate
      : null;
  }

  private validateTime(time: number, equalAndAboveTo: number): boolean {
    return !isNaN(time) && typeof time === 'number' && time >= equalAndAboveTo;
  }

  private emitTimeOfTake() {
    if (!this.notifyTime) {
      return;
    }

    const composedEdits = this.composedEditTakes$.value;
    if (!composedEdits || composedEdits.length === 0) {
      return;
    }
    const endInSeconds = this.convertFrameToSeconds(this.endInFrame$.value);

    const currentComposedEdit = composedEdits[0];
    if (currentComposedEdit.videoLayers.length === 0) {
      for (const lottie of currentComposedEdit.lottiesLayersAndOptions) {
        const lottieUniqueId = this.getLottieUniqueId(
          currentComposedEdit.id,
          lottie.lottieLayer.lottieId
        );

        const animationItem =
          this.lottiePlayersMap.get(lottieUniqueId)?.animationItem;
        if (!animationItem) {
          break;
        }

        animationItem.addEventListener('enterFrame', (frame) => {
          /// We count the number of frames the lottie has been through, because when he jumps back to loop mark he skips back to
          /// frame 'x' instead of the real current time we are in to.

          const currentPlayingFrame =
            this.lottiePlayer.getCurrentPlayingFrame() * 30;

          const frameInSeconds = currentPlayingFrame / 1000;
          if (endInSeconds <= frameInSeconds) {
            this.finishedPlayingEditTake.emit(this.composeConfigs$.value.id);
            animationItem.destroy();
            return;
          }
          this.currentLottieTime.emit(currentPlayingFrame);
        });
      }
      return;
    }

    if (this.vgApisMap.size === 0) {
      return;
    }
    /// If we get here, it means we do have video layers

    for (const [id, vgApiAndCanBePlayed] of this.vgApisMap) {
      const defaultMedia = vgApiAndCanBePlayed.vgApi.getDefaultMedia();

      defaultMedia.subscriptions.timeUpdate.subscribe((data) => {
        this.currentTime = defaultMedia.currentTime;
        this.currentVideoTime.emit(defaultMedia.currentTime * 1000);

        if (defaultMedia.currentTime >= endInSeconds - 0.1) {
          this.handleVideoEnded();
        }
      });

      this.videoEndedSubscription = defaultMedia.subscriptions.ended.subscribe(
        (ended) => {
          this.handleVideoEnded();
        }
      );
    }
  }

  private getJassubUniqueId() {
    const composeConfigs = this.composeConfigs$.value;
    if (!composeConfigs) {
      return null;
    }
    return composeConfigs.id;
  }

  private updateSubtitleColorsAndStyles(): void {
    const composeConfigs = this.composeConfigs$.value;
    if (!composeConfigs?.subtitlesConfigs?.styles) {
      return;
    }
    const { uniqueId, assText } = this.getDataForJassub();

    this.jassubService.updateSubtitlesWithContent(assText, uniqueId);
  }

  private initalizeSubtitles(vgApi: VgApiService) {
    const defaultMedia = vgApi?.getDefaultMedia();
    const composeConfigs = this.composeConfigs$.value;

    if (
      !this.canvas?.nativeElement ||
      !defaultMedia.elem ||
      !composeConfigs ||
      !composeConfigs.subtitlesConfigs?.take?.copy?.transcript?.sentences.length
    ) {
      return;
    }

    const { uniqueId, assText, fonts } = this.getDataForJassub();

    const canvasWidth = this.canvas.nativeElement.clientWidth;
    const canvasHeight = this.canvas.nativeElement.clientHeight;
    const a =
      this.composeConfigs.videoLottieConfigs[0].videoLayers[0].videoPosition;
    const prescaleFactor = ((a.height / 100) * canvasHeight) / 1080;

    this.jassubService.initializeCanvasJassub(
      this.canvas.nativeElement,
      defaultMedia.elem,
      uniqueId,
      fonts,
      prescaleFactor,
      assText,
      'content'
    );

    const fontUrls: string[] = [];
    for (const font of this.fonts) {
      const regualrFont = this.baseCdnUrl + font.regularUrl;
      if (font.italicUrl) {
        const italicFont = this.baseCdnUrl + font.italicUrl;
        fontUrls.push(italicFont);
      }
      fontUrls.push(regualrFont);
    }

    this.jassubService.addFontToJassub(fontUrls, uniqueId);
  }

  private getDataForJassub() {
    const uniqueId = this.getJassubUniqueId();
    const assText = this.getAssText(uniqueId);
    const fonts = this.composeConfigs.subtitlesConfigs.fonts;
    return { uniqueId, assText, fonts };
  }

  private getAssText(uniqueId: string) {
    const composeConfigs = this.composeConfigs$.value;
    // this.updateSubtitlesFonts();

    // const updatedConfigs = this.updateStylesWithColors(composeConfigs);

    if (!composeConfigs) {
      return;
    }

    // Because the styles are being updated in the edit room so the compose configs
    return this.createAssText(composeConfigs.subtitlesConfigs, uniqueId);
  }
}
