<div id="page-wrapper">
  <div id="inner-page">
    <!-- <app-series-side-nav #sideNav [class]="'opened'"></app-series-side-nav> -->

    <div class="right-side-wrapper">
      <div class="dashboard-page" autosize *ngIf="this.project">
        <!-- <mat-drawer #drawer class="example-sidenav" mode="top" position="end">
                  <p>Auto-resizing sidenav</p>
                </mat-drawer> -->
        <div>
          <div class="main-wrapper">
            <div class="top-section">
              <app-sidenav-toggle></app-sidenav-toggle>

              <project-title
                [projcetName]="project.name"
                [allowNameChange]="projectCreativeDone"
                [currentPhase]="ProjectStatus.PLANNING"
                [projectStatus]="this.project?.statuses?.general"
                [projectId]="this.project?.id"
                (projectNameChanged)="updateProjectName($event)">
              </project-title>
              <button
                mat-raised-button
                color="primary"
                id="go-to-studio"
                matTooltip="Move to Studio"
                (click)="startProduction()">
                <span class="btn-content"
                  >Next <mat-icon>navigate_next</mat-icon>
                </span>
              </button>
            </div>

            <div class="brief-area">
              <h5>Brief</h5>
              <h4 class="brief" *ngIf="projectBriefFormControl">
                {{ this.projectBriefFormControl.value }}
              </h4>
            </div>
          </div>
          <storyboard
            *ngIf="this.project"
            (sceneTitleChanged)="updateSceneTitle($event)"
            (structureFinish)="openPaymentDialog()"
            #storyboard></storyboard>
        </div>
      </div>
    </div>
  </div>
</div>
